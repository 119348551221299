import React, { useCallback } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AUTH_COOKIE_NAME } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';

// store
import {
    selectDesignCodeProject,
    updateProject,
} from 'features/projects/state/projectState';

// services
import { CookieService } from 'services/cookie/CookieService';

// types
import { VispakOfferStatus, VispakRoomStatusConst } from 'types/StatusTypes';
import { PlateType } from 'features/moodboard/types';
import { appPath, AppPath } from 'routes';

// Utility functions

/**
 * Check if the reminder date is within next 7 days.
 *
 * @param reminderDate - The reminder date string.
 * @returns A boolean indicating if the reminder date is within the next 7 days.
 */
export const isReminderDateWithinWeek = (reminderDate: string) => {
    return (
        new Date(reminderDate).getTime() - new Date().getTime() <
        7 * 24 * 60 * 60 * 1000
    );
};

/**
 * Generate a timestamp string in the format DD-MM-YYYY_HH-MM-SS.
 *
 * @returns A timestamp string.
 */
export function generateCurrentTimestampString(): string {
    const currentDate = new Date()
        .toLocaleString('et-EE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZone: 'Europe/Tallinn',
        })
        .replace(/\./g, '-')
        .replace(/, /, '_')
        .replace(/:/g, '-');
    return currentDate;
}

/**
 * Generate a unique id with string length of 8 based on
 * the current timestamp and random number.
 *
 * @returns A unique id.
 */
export function generateHashFromTimestamp(): string {
    return Math.floor(Math.random() * Date.now()).toString(36);
}

export function generateRandomPosition(): [number, number, number] {
    const min = -1;
    const max = 1;
    const step = 0.1;
    const numberOfSteps = Math.floor((max - min) / step) + 1;
    const randomStep = Math.floor(Math.random() * numberOfSteps);
    const randomX = parseFloat((min + randomStep * step).toFixed(1));

    return [randomX, 0, 0];
}

export function generatePlateFromProduct(
    product: PIMProduct,
    plates: PlateType[]
): PlateType {
    const plateThickness = 1;
    const lowestLayerHeight = 0.1;
    const maxHeight = (plates.length + 1) * lowestLayerHeight;

    return {
        id: generateHashFromTimestamp(),
        // only add objects which have url present
        textures: [
            ...product.designImageSlider
                .filter((image) => image.designImage?.url)
                .map((image) => ({
                    src: image.designImage.url,
                    scale: [1, 1, 1],
                    isSelected: false,
                })),
            ...product.interiorImageSlider
                .filter((image) => image.interiorImage?.url)
                .map((image) => ({
                    src: image.interiorImage.url,
                    scale: [1, 1, 1],
                    isSelected: false,
                })),
            ...product.referenceAlbumSlider
                .filter((referenceAlbum) => referenceAlbum.referenceAlbums?.url)
                .map((referenceAlbum) => ({
                    src: referenceAlbum.referenceAlbums.url,
                    scale: [1, 1, 1],
                    isSelected: false,
                })),
        ],
        position: generateRandomPosition(),
        scale: [1, 1, plateThickness],
        rotation: [-Math.PI / 2, 0, 0],
        height: maxHeight, // layering height
        transformScale: [1, 1, plateThickness],
        locked: false,
        pimProductId: product.id,
    };
}

// HTML DOM

export function getElementDistanceFromTop(
    elementRef: React.RefObject<HTMLElement | null>
) {
    if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        return rect.top;
    }
    return 0;
}

export function getElementDistanceFromLeft(
    elementRef: React.RefObject<HTMLElement | null>
) {
    if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        return rect.left;
    }
    return 0;
}

export function getSizeFromHalfWidth(
    width: number,
    percentage: number
): number {
    const halfWidth = width / 2;
    return halfWidth * percentage;
}

// Project helpers

export function visualOfferHasAnyPublicRooms(
    currentProject: VisualOffering | undefined
) {
    const currentOfferSolutions = currentProject?.spacialSolutions;
    let isAnyRoomPublic = false;
    if (currentOfferSolutions) {
        currentOfferSolutions?.every((solution: SpacialSolution) => {
            const foundPublicRoom = !!solution?.rooms?.some(
                (room: Room) => room.status === VispakRoomStatusConst.PUBLIC
            );
            isAnyRoomPublic = foundPublicRoom;

            // break out of every if public room found
            return !foundPublicRoom;
        });
    }

    return isAnyRoomPublic;
}

/**
 * Custom hook to update the status of a project.
 *
 * @returns A function to set the project status.
 */
export const useSetProjectStatus = () => {
    const dispatch: AppDispatch = useDispatch();

    const setProjectStatus = useCallback(
        async (
            visualOfferId: string,
            status: VispakOfferStatus
        ): Promise<VisualOffering | undefined> => {
            if (!visualOfferId) return undefined;

            try {
                const modifiedStatusInVisualOffer = await dispatch(
                    updateProject({
                        id: visualOfferId,
                        updateData: { status },
                    })
                )?.unwrap?.();

                return modifiedStatusInVisualOffer;
            } catch (error) {
                // Optionally handle the error
                return undefined;
            }
        },
        [dispatch]
    );

    return setProjectStatus;
};

// Auth helpers

/**
 * Custom hook to handle redirection based on authentication status and project availability.
 *
 * @param projects - Array of current VisualOfferings.
 * @returns A memoized redirect function.
 */
export function useRedirectIfNoId(projects: VisualOffering[]) {
    const authCookie = CookieService.getCookieByName(AUTH_COOKIE_NAME);
    const navigate: NavigateFunction = useNavigate();
    const path: AppPath = appPath;
    const designCodeProject = useSelector(selectDesignCodeProject);

    const redirect = useCallback(() => {
        if (authCookie) {
            if (!projects.length) {
                // Offers empty - possibly refresh
                navigate(path.projects);
            }
        } else {
            // no auth cookie & no design code project
            if (!designCodeProject) {
                navigate(path.login);
                return;
            }
        }
    }, [authCookie, projects, navigate, path, designCodeProject]);

    return redirect;
}

/**
 * Custom hook to check if side effects should return early.
 * Important for preventing side effects to trigger after
 * components are unmounted and no return on unmount was provided.
 *
 * @param projects - Array of current VisualOfferings.
 * @returns A memoized function to check if the user should navigate away.
 */
export function useShouldNavigateAway(projects: VisualOffering[]) {
    const authCookie = CookieService.getCookieByName(AUTH_COOKIE_NAME);
    return useCallback(() => {
        return !authCookie || !projects.length;
    }, [authCookie, projects]);
}

// Start of Selection
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PLACEHOLDER_IMAGE_URL } from 'config';

// mui
import { Box, Typography, Grid, CircularProgress } from '@mui/material';

// ui
import ProductItemOverview from './ProductItemOverview';
import { colors } from 'theming/colors';

// store
import { selectDesignCodeProject } from 'features/projects/state/projectState';

// services
import { PIMService } from 'services/PIMService';

// types
import {
    ProductListViewCategorizationType,
    ProductListViewVariation,
} from './ProductListView';
import { CategorizationTypeConst } from './ProductListView';

interface ProductDisplayOverviewProps {
    currentProject: VisualOffering | undefined;
    allPopulatedVariations: ProductListViewVariation[] | undefined;
    pimProducts: PIMProduct[];
    categorizationType: ProductListViewCategorizationType;
    isProductsLoading: boolean;
}

const ProductDisplayOverview = (props: ProductDisplayOverviewProps) => {
    const { t: translate } = useTranslation();

    // local state

    // individual image loading states and fetched snapshot urls
    const [imageLoading, setImageLoading] = useState<boolean[]>([]);
    const [fetchedSnapshotUrl, setFetchedSnapshotUrl] = useState<string[]>([]);

    // properties

    const designCodeProject = useSelector(selectDesignCodeProject);

    const baseUrl = window.location.origin;
    const {
        currentProject,
        allPopulatedVariations,
        pimProducts,
        categorizationType,
    } = props;

    const isCategorizationByProduct =
        categorizationType.id === CategorizationTypeConst.BY_PRODUCT;

    const { isProductsLoading } = props;

    // features

    const setSnapshotLoading = (index: number, loading: boolean) => {
        setImageLoading((prev) => {
            const newArray = [...prev];
            newArray[index] = loading;
            return newArray;
        });
    };

    const processImage = useCallback(
        async (snapshotURL: string, index: number) => {
            const pimService = new PIMService();

            // Set initial loading state for this specific image
            setSnapshotLoading(index, true);

            if (!snapshotURL) {
                setFetchedSnapshotUrl((prev) => {
                    const newArray = [...prev];
                    newArray[index] = '';
                    return newArray;
                });

                // Set loading to false for current image
                setSnapshotLoading(index, false);
                return;
            }

            if (designCodeProject) {
                const designCodeParameter = currentProject?.designCode
                    ? `?designcode=${currentProject.designCode}`
                    : '';

                setFetchedSnapshotUrl((prev) => {
                    const newArray = [...prev];
                    newArray[index] = snapshotURL + designCodeParameter;
                    return newArray;
                });
            } else {
                await pimService.getImage(snapshotURL).then((image) => {
                    if (image) {
                        setFetchedSnapshotUrl((prev) => {
                            const newArray = [...prev];
                            newArray[index] = URL.createObjectURL(image);
                            return newArray;
                        });
                    } else {
                        setFetchedSnapshotUrl((prev) => {
                            const newArray = [...prev];
                            newArray[index] = '';
                            return newArray;
                        });
                    }
                });
            }

            // Set loading to false for this specific image
            setSnapshotLoading(index, false);
        },
        [currentProject?.designCode, designCodeProject]
    );

    // side effects

    useEffect(() => {
        if (!allPopulatedVariations?.length) return;

        // go through each variation and start loading found snapshotURL
        allPopulatedVariations.forEach((variation, index) => {
            if (!variation || !variation.variationId) return;

            const snapshotURL =
                variation?.variationSnapshot?.sizes?.thumbnail?.url ||
                variation?.variationSnapshot?.sizes?.medium?.url ||
                variation?.variationSnapshot?.sizes?.large?.url ||
                variation?.variationSnapshot?.url ||
                '';

            processImage(snapshotURL, index);
        });
    }, [allPopulatedVariations, processImage]);

    // categorized by product
    if (isCategorizationByProduct) {
        return (
            <Box component="div" sx={styles.container}>
                <Box component="div" sx={styles.productItemBox}>
                    {isProductsLoading && (
                        <CircularProgress
                            size={20}
                            sx={styles.circularLoader}
                        />
                    )}
                    {/* Product overview grid table */}
                    <Grid container spacing={0} columns={10}>
                        {pimProducts.length &&
                            pimProducts
                                // sort by product name a to z
                                .sort((a: PIMProduct, b: PIMProduct) =>
                                    a.name.localeCompare(b.name)
                                )
                                .map((product, index, array) => {
                                    return (
                                        // PIM product info overview
                                        <ProductItemOverview
                                            key={product.id}
                                            product={product}
                                            translate={translate}
                                            index={index}
                                            array={array}
                                        />
                                    );
                                })}
                    </Grid>
                </Box>
            </Box>
        );
    }

    // categorized by room
    return (
        <Box component="div" sx={styles.container}>
            {allPopulatedVariations
                // sort by room name a to z
                ?.sort((a, b) => a.roomName.localeCompare(b.roomName))
                ?.map((variation, index) => {
                    if (!variation || !variation.variationId) return null;

                    return (
                        <React.Fragment key={variation.variationId}>
                            <Box
                                component="div"
                                key={variation.variationId}
                                sx={{
                                    ...styles.productItemBox,
                                }}
                            >
                                {/* Product item header */}

                                <Box component="div" sx={styles.roomGroup}>
                                    <Box
                                        component="div"
                                        sx={styles.roomGroupImageContainer}
                                    >
                                        <Box
                                            component="div"
                                            sx={styles.roomGroupImage}
                                        >
                                            {imageLoading[index] && (
                                                <CircularProgress
                                                    size={20}
                                                    sx={styles.circularLoader}
                                                />
                                            )}

                                            {!imageLoading[index] && (
                                                <img
                                                    src={
                                                        fetchedSnapshotUrl[
                                                            index
                                                        ]
                                                    }
                                                    alt={'thumbnail'}
                                                    onError={(
                                                        e: React.SyntheticEvent<
                                                            HTMLImageElement,
                                                            Event
                                                        >
                                                    ) => {
                                                        e.currentTarget.onerror =
                                                            null;
                                                        e.currentTarget.src = `${baseUrl}${PLACEHOLDER_IMAGE_URL}`;
                                                    }}
                                                    style={{
                                                        width: '6rem',
                                                        height: '6rem',
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                    <Box
                                        component="div"
                                        sx={styles.roomGroupTitle}
                                    >
                                        <Typography
                                            sx={{
                                                ...styles.roomGroupTitleText,
                                                fontWeight: '600',
                                            }}
                                        >
                                            {variation.roomName}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                ...styles.roomGroupTitleSubText,
                                            }}
                                        >
                                            {translate(
                                                'views.productList.variationName'
                                            ).toUpperCase()}
                                            {': '}
                                            {variation.variationName}
                                        </Typography>
                                    </Box>
                                </Box>

                                {/* Product item details - PIM product info */}
                                {isProductsLoading && (
                                    <CircularProgress
                                        size={20}
                                        sx={styles.circularLoader}
                                    />
                                )}
                                {/* Product overview grid table */}
                                <Grid container spacing={0} columns={10}>
                                    {pimProducts.length &&
                                        pimProducts
                                            .filter((product: PIMProduct) =>
                                                variation.variationData.plates.some(
                                                    (plate: any) =>
                                                        plate.pimProductId ===
                                                        product.id
                                                )
                                            )
                                            // sort by product name a to z
                                            .sort(
                                                (
                                                    a: PIMProduct,
                                                    b: PIMProduct
                                                ) =>
                                                    a.name.localeCompare(b.name)
                                            )
                                            .map(
                                                (
                                                    product: PIMProduct,
                                                    index: number,
                                                    array: PIMProduct[]
                                                ) => {
                                                    return (
                                                        // PIM product info overview
                                                        <ProductItemOverview
                                                            key={product.id}
                                                            product={product}
                                                            translate={
                                                                translate
                                                            }
                                                            index={index}
                                                            array={array}
                                                        />
                                                    );
                                                }
                                            )}
                                </Grid>
                            </Box>
                        </React.Fragment>
                    );
                })}
        </Box>
    );
};

export default ProductDisplayOverview;

const styles = {
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '0.5rem',

        boxSizing: 'border-box',
    },

    // -- Product item box --
    productItemBox: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '1rem',

        boxSizing: 'border-box',
        padding: '0.5rem 5rem',
    },

    // -- Product item header --
    roomGroup: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '1rem',

        paddingBottom: '1rem',
        borderBottom: '1px solid ' + colors.lGrey,
    },
    roomGroupImageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        maxWidth: '8rem',
        maxHeight: '8rem',
        aspectRatio: '1/ 1',
        borderRadius: '50%',
        overflow: 'hidden',
    },
    roomGroupImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    roomGroupTitle: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '0',
    },
    roomGroupTitleText: {
        fontSize: '1.2rem',
    },
    roomGroupTitleSubText: {
        fontSize: '0.8rem',
    },
    circularLoader: {
        margin: 'auto',
        marginTop: '1rem',
    },
};

import axios, { AxiosResponse } from 'axios';
import { API_URL, AUTH_COOKIE_NAME } from 'config';
import { CookieService } from 'services/cookie/CookieService';
import qs from 'qs';
import { VispakOfferStatusConst } from 'types/StatusTypes';
import { FetchProjectsParams } from 'features/projects/state/projectState';

export class VisualOfferingsService {
    public url;
    public token;
    public config;

    constructor() {
        this.url = `${API_URL}/api/visualOfferings`;
        this.token = CookieService.getCookieByName(AUTH_COOKIE_NAME);
        this.config = {
            headers: {
                Authorization: `Bearer ${this.token}`,
            },
        };
    }

    public async getVisualOfferingsWithPaginationLimit(
        params: FetchProjectsParams
    ): Promise<PaginatedVisualOfferings> {
        try {
            // filter out soft deleted offers or status if provided
            const where = {
                status: params.status
                    ? { equals: params.status }
                    : { not_equals: VispakOfferStatusConst.DELETED },
                ...(params.hasReminderDate && {
                    reminderDate: {
                        exists: true,
                    },
                }),
            };

            const queryString = qs.stringify(
                {
                    where,
                    page: params.page,
                    limit: params.limit,
                    depth: 1,
                },
                { encode: false }
            );

            const response: AxiosResponse<PaginatedVisualOfferings> =
                await axios.get(`${this.url}?${queryString}`, this.config);
            return response.data;
        } catch (error: any) {
            throw new Error('Visual offerings fetch failed: ' + error?.message);
        }
    }

    public async getVisualOfferingById(id: string): Promise<VisualOffering> {
        try {
            const response: AxiosResponse<VisualOffering> = await axios.get(
                this.url + `/${id}`,
                { ...this.config, params: { depth: 4 } }
            );
            return response.data;
        } catch (error: any) {
            throw new Error('Visual offering fetch failed:', error?.message);
        }
    }

    /**
     * @param designcode
     * @returns VisualOffering without auth token
     */
    public async getVisualOfferingByDesignCode(
        designcode: string
    ): Promise<PaginatedVisualOfferings> {
        try {
            const response: AxiosResponse<PaginatedVisualOfferings> =
                await axios.get(this.url, {
                    params: { depth: 4, designcode },
                });
            return response.data;
        } catch (error: any) {
            throw new Error(
                'Visual offering fetch by design code failed:',
                error?.message
            );
        }
    }

    public async createVisualOffering(
        data: VisualOfferingSaveData,
        comments: string | undefined = undefined
    ): Promise<VisualOffering> {
        try {
            const response: AxiosResponse<CRUDVisualOfferingResponse> =
                await axios.post(
                    this.url,
                    {
                        name: data.name,
                        odooLead: data.odooLead,
                        ...(comments && { comments: [{ comment: comments }] }),
                    },
                    this.config
                );
            return response.data.doc;
        } catch (error: any) {
            throw new Error('Visual offering creation failed:', error?.message);
        }
    }

    public async duplicateVisualOffering(
        data: VisualOfferingDuplicationData
    ): Promise<VisualOffering> {
        const duplicationUrl = `${API_URL}/v0/duplication/visualOffering`;
        try {
            const response: AxiosResponse<CRUDVisualOfferingResponse> =
                await axios.post(duplicationUrl, data, this.config);

            return response.data.doc;
        } catch (error: any) {
            throw new Error(
                'Visual offering duplication failed:',
                error?.message
            );
        }
    }

    // TODO: TO BE DELETED - use PATCH for soft delete
    public async deleteVisualOffering(id: string): Promise<VisualOffering> {
        try {
            const response: AxiosResponse<VisualOffering> = await axios.delete(
                this.url + `/${id}`,
                this.config
            );
            return response.data;
        } catch (error: any) {
            throw new Error('Visual offering deletion failed:', error?.message);
        }
    }

    public async updateVisualOffering(
        id: string,
        updateData: Partial<VisualOffering> | { spacialSolutions: string[] }
    ): Promise<VisualOffering> {
        try {
            const response: AxiosResponse<CRUDVisualOfferingResponse> =
                await axios.patch(this.url + `/${id}`, updateData, {
                    ...this.config,
                    params: { depth: 4 },
                });

            return response.data.doc;
        } catch (error) {
            throw new Error('Visual offering update failed');
        }
    }
}

import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// ui
import TopBar from 'components/menu/TopBar';
import SendOfferDialog from 'components/SendOfferDialog';

// store
import {
    selectGlobalModals,
    setGlobalModalOfferSend,
} from 'features/projects/state/projectState';

// types
import { AppDispatch } from 'store';
import {
    GlobalModalState,
    GlobalModalData,
} from 'features/projects/state/projectState';

export interface RootProps {}

const Root = (props: RootProps) => {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();

    // local state

    const [isSendOfferDialogOpen, setIsSendOfferDialogOpen] = useState(false);

    // properties

    const lang = 'en-US';
    const globalModals: GlobalModalState = useSelector(selectGlobalModals);

    // features

    // side effects

    useEffect(() => {
        if (globalModals.offer.sendOffer.open) {
            setIsSendOfferDialogOpen(true);
        } else {
            setIsSendOfferDialogOpen(false);
        }
    }, [globalModals]);

    return (
        <>
            <TopBar
                imageSource="/img/floorin-interior-logo.png"
                imageAlt="Floorin Interior"
                logoutText={t(`logout`)}
                settingsText={t(`settings`)}
                languages={['en-US', 'et-EE']}
                languageCode={lang}
            />
            <Outlet />

            {/* Modals */}
            <SendOfferDialog
                isSendOfferDialogOpen={isSendOfferDialogOpen}
                onCloseSendOfferDialog={() =>
                    dispatch(
                        setGlobalModalOfferSend({
                            open: false,
                            id: null,
                        } as GlobalModalData)
                    )
                }
            />
        </>
    );
};

export default Root;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from 'components/base/Button';
import { Box, Divider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

// services
import { EmailService } from 'services/EmailService';

// config
import { colors } from 'theming/colors';

// types
import { AppDispatch } from 'store';
import { VispakOfferStatusConst } from 'types/StatusTypes';

export interface RequestProductSamplesDialogProps {
    isOpen: boolean;
    onClose: () => void;
    currentProject: VisualOffering | undefined;
    selectedSamples: ProductSampleData[];
    products: PIMProduct[];
    resetSelectedSamples: () => void;
}

type ResponseMessage = {
    type: 'success' | 'error';
    message: string;
};

const RequestProductSamplesDialog = (
    props: RequestProductSamplesDialogProps
) => {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();

    const emailService = new EmailService();

    // local state

    const [isLoading, setIsLoading] = useState(false);
    const [responseMessage, setResponseMessage] =
        useState<ResponseMessage | null>(null);

    // properties

    const requestSamplesIsDisabled =
        isLoading ||
        !!responseMessage?.message ||
        props.currentProject?.status !== VispakOfferStatusConst.PUBLIC;

    const mappedSamples = props.selectedSamples.map(
        (sample: ProductSampleData) => {
            const product = props.products.find(
                (product) => product.id === sample.productId
            );
            return {
                productId: sample.productId,
                sampleId: sample.sampleId,
                productName: product?.name,
                sampleName: product?.category?.value?.samples?.find(
                    (pimSample: PIMProductSample) =>
                        pimSample.value.id === sample.sampleId
                )?.value?.name,
            };
        }
    );

    // features

    const onLocalClose = () => {
        props.resetSelectedSamples();
        setResponseMessage(null);
        props.onClose();
    };

    const handleRequestSamples = async () => {
        if (
            !props.currentProject ||
            !props.selectedSamples.length ||
            props.currentProject.status !== VispakOfferStatusConst.PUBLIC
        ) {
            return;
        }

        setIsLoading(true);

        const response = await emailService
            .postSamplesByEmailRequest(
                props.currentProject.id,
                props.selectedSamples
            )
            .catch((error) => {
                console.error('Error requesting samples by email:', error);
            });

        if (response?.status >= 200 && response?.status < 300) {
            // show success on modal
            setResponseMessage({
                type: 'success',
                message: t(`component.requestProductSamplesDialog.success`),
            });
        } else {
            // show error on modal
            setResponseMessage({
                type: 'error',
                message: t(`component.requestProductSamplesDialog.error`),
            });
        }

        setTimeout(() => {
            setResponseMessage(null);
            props.onClose();
        }, 3000);

        setIsLoading(false);
        props.resetSelectedSamples();
    };

    return (
        <Dialog open={props.isOpen} onClose={onLocalClose}>
            {/* Right top close button */}
            <IconButton
                aria-label="close"
                onClick={onLocalClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.main,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Title */}
            <Box component="div" sx={styles.titleBox}>
                <DialogTitle>
                    {t(`component.requestProductSamplesDialog.title`)}
                </DialogTitle>
            </Box>

            {/* Content */}
            <DialogContent>
                {!responseMessage && (
                    <Box component="div" sx={styles.contentBox}>
                        <Typography variant="body1">
                            {t(`component.requestProductSamplesDialog.message`)}
                        </Typography>
                        <Box component="div" sx={styles.samplesBox}>
                            {mappedSamples.map((sample) => (
                                <Box
                                    component="div"
                                    key={sample.productId + sample.sampleId}
                                    sx={styles.sampleItem}
                                >
                                    <Typography variant="subtitle2">
                                        {sample.productName}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        {sample.sampleName}
                                    </Typography>
                                    <Divider />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}

                {responseMessage && (
                    <Box component="div" sx={styles.responseMessageBox}>
                        <Typography
                            variant="body1"
                            sx={{
                                color:
                                    responseMessage.type === 'success'
                                        ? colors.green
                                        : colors.red,
                            }}
                        >
                            {responseMessage.message}
                        </Typography>
                    </Box>
                )}
            </DialogContent>

            {/* Actions */}
            <DialogActions sx={styles.dialogActions}>
                <Button
                    sx={styles.buttonCancel}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={onLocalClose}
                >
                    {t(`cancel`)}
                </Button>
                <Button
                    sx={styles.buttonSubmit}
                    variant={'contained'}
                    type="submit"
                    onClick={handleRequestSamples}
                    disabled={requestSamplesIsDisabled}
                >
                    {isLoading ? <CircularProgress size={20} /> : t(`submit`)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RequestProductSamplesDialog;

const styles = {
    titleBox: {
        marginRight: '2rem',
    },
    contentBox: {
        marginTop: '1rem',
    },
    responseMessageBox: {
        marginTop: '1rem',
    },
    samplesBox: {
        marginTop: '1rem',
        maxHeight: '20rem',
        overflow: 'auto',
    },
    sampleItem: {
        padding: '0.5rem 0 0 0',
    },
    dialogActions: {
        justifyContent: 'flex-end',
        gap: '0.4rem',
        margin: '0px 0.5rem 0.5rem 0px',
    },
    buttonCancel: {
        height: '2.8rem',
    },
    buttonSubmit: {
        height: '2.8rem',
    },
};

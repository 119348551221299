import { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AUTH_COOKIE_NAME, DEFAULT_PAGINATION_LIMIT } from 'config';
import { colors } from 'theming/colors';

// ui
import { Box, Divider, Typography } from '@mui/material';
import NewProjectDialog from 'components/specific/projectList/NewProjectDialog';
import OfferMenu from 'features/projects/components/OfferMenu';
import OffersTable from 'features/projects/components/OffersTable';
import BreadcrumbNavigation from 'components/BreadcrumbNavigation';
import PaginationBar from 'features/projects/components/PaginationBar';

// store
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchProjects,
    setSelectedProject,
    selectProjects,
} from 'features/projects/state/projectState';
import {
    setUserDetails,
    selectUserDetails,
} from 'features/authentication/state/authState';
import { AppDispatch } from 'store';

// services
import { CookieService } from 'services/cookie/CookieService';
import { VispakUsersService } from 'services/vispakUsers/VispakUsersService';

// types
import { VispakOfferStatus, VispakOfferStatusConst } from 'types/StatusTypes';

export interface ProjectListViewProps {}

export interface RowData {
    id: string | number;
    offer: string;
    offerURL: string;
    address: string;
    client: string;
    designer: string;
    status: VispakOfferStatus;
    lastChanged: string;
}

const ProjectListView = (props: ProjectListViewProps) => {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    // local state

    const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);

    const [showArchived, setShowArchived] = useState(false);
    const [showTimeCritical, setShowTimeCritical] = useState(false);

    const [activeProjectId, setActiveProjectId] = useState('');
    const [menuAnchorElProjectMenu, setMenuAnchorElProjectMenu] =
        useState<null | HTMLElement>(null);

    // properties

    const vispakUsersService = useMemo(() => new VispakUsersService(), []);
    const userDetails = useSelector(selectUserDetails);
    const authCookie = CookieService.getCookieByName(AUTH_COOKIE_NAME);
    const pagination: PaginatedVisualOfferings = useSelector(selectProjects);

    // features

    // Update the archived toggle
    const handleArchivedToggle = () => {
        setShowArchived(!showArchived);
        // If switching to archived, ensure time critical is off
        if (!showArchived) {
            setShowTimeCritical(false);
        }
    };

    // Update the time critical toggle
    const handleTimeCriticalToggle = () => {
        setShowTimeCritical(!showTimeCritical);
    };

    const redirectIfNoId = useCallback(async () => {
        // refresh or token expiry
        if (authCookie) {
            if (!userDetails?.id) {
                const userDetails =
                    await vispakUsersService.getUserDetailsByToken(authCookie);
                if (userDetails) {
                    dispatch(setUserDetails(userDetails));
                }
            }
        } else {
            // no auth cookie
            navigate('/login');
        }
    }, [authCookie, userDetails, navigate, dispatch, vispakUsersService]);

    const getInitialOffers = useCallback(async () => {
        if (pagination.totalPages === 0) {
            dispatch(
                fetchProjects({
                    page: 1,
                    limit: DEFAULT_PAGINATION_LIMIT,
                })
            );
        }
    }, [dispatch, pagination.totalPages]);

    const onPaginationChange = useCallback(
        async (page: number) => {
            await dispatch(
                fetchProjects({ page, limit: DEFAULT_PAGINATION_LIMIT })
            );
        },
        [dispatch]
    );

    // side effects

    useEffect(() => {
        (async function () {
            await redirectIfNoId();
        })();
    }, [redirectIfNoId, userDetails]);

    useEffect(() => {
        // reset selected project
        dispatch(setSelectedProject());
        getInitialOffers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // fetch all projects by archived status or time critical (reminderDate field exists)
        dispatch(
            fetchProjects({
                page: 1,
                limit:
                    showArchived || showTimeCritical
                        ? 100
                        : DEFAULT_PAGINATION_LIMIT, // High limit for archived & time critical
                status: showArchived
                    ? VispakOfferStatusConst.ARCHIVED
                    : undefined,
                hasReminderDate: showTimeCritical ? true : undefined,
            })
        );
    }, [dispatch, showArchived, showTimeCritical]);

    return (
        <>
            <Divider />
            <Box component="div" sx={style.breadcrumbBox}>
                <BreadcrumbNavigation />
            </Box>
            <Box component="div" sx={style.parentBox}>
                <OffersTable
                    setMenuAnchorElProjectMenu={setMenuAnchorElProjectMenu}
                    setActiveProjectId={setActiveProjectId}
                    setNewProjectModalOpen={() => setNewProjectModalOpen(true)}
                    showArchived={showArchived}
                    showTimeCritical={showTimeCritical}
                    handleArchivedToggle={handleArchivedToggle}
                    handleTimeCriticalToggle={handleTimeCriticalToggle}
                />
            </Box>
            <Box component="div" sx={style.paginationBox}>
                <Typography variant="body1">
                    {t(`views.projectList.nrOfRows`)}: {pagination.docs.length}{' '}
                    ({pagination.totalDocs})
                </Typography>
                <PaginationBar
                    totalPages={pagination.totalPages}
                    currentPage={pagination.page}
                    onPageChange={onPaginationChange}
                />
            </Box>

            {/* Modals */}
            <NewProjectDialog
                isDuplicate={false}
                open={newProjectModalOpen}
                onClose={() => setNewProjectModalOpen(false)}
            />
            <OfferMenu
                onCloseOfferMenu={() => setMenuAnchorElProjectMenu(null)}
                menuAnchorElProjectMenu={menuAnchorElProjectMenu}
                activeProjectId={activeProjectId}
                showArchived={showArchived}
                showTimeCritical={showTimeCritical}
            />
        </>
    );
};

export default ProjectListView;

const style = {
    breadcrumbBox: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '2.7rem',

        backgroundColor: colors.white,
    },
    parentBox: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        overflowX: 'visible',
        overflowY: 'auto',

        paddingTop: '0rem',
        paddingLeft: '5rem',
        paddingRight: '5rem',
        gap: '2rem',
    },
    paginationBox: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        // flex-grow, flex-shrink and flex-basis
        flex: '0 0 auto',

        padding: '1rem 5rem',
    },
};

import React from 'react';
import { IconButton } from '@mui/material';
import { ToolType } from './types';
import { colors } from '../../theming/colors';

interface ToolButtonInterface {
    selectedTool: ToolType;
    toolType: ToolType;
    locked: boolean;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    IconComponent: JSX.Element;
    onMouseLeave?: () => void;
}

const ToolButton: React.FC<ToolButtonInterface> = ({
    selectedTool,
    toolType,
    locked,
    handleClick,
    IconComponent,
    onMouseLeave,
}) => {
    return (
        <IconButton
            disabled={locked}
            sx={{
                color: selectedTool === toolType ? colors.orange : 'black',
                borderRadius: 0,
                backgroundColor:
                    selectedTool === toolType ? colors.lGrey : 'white',
                '&:hover': {
                    backgroundColor: colors.borderGrey,
                },
            }}
            onClick={handleClick}
            onMouseLeave={onMouseLeave || (() => {})}
        >
            {IconComponent}
        </IconButton>
    );
};

export default ToolButton;

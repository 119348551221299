import React from 'react';

// mui
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

// ui
import { colors } from 'theming/colors';

// types
import { FiltersConst } from './MaterialPicker';

export interface MaterialPickerFilterProps {
    filterName: string;
    filterArray: PIMFilterGroup[] | PIMFilterCollection[] | PIMFilterFeature[];
    selectedFilterGroups: string[];
    selectedFeatureValues?: string[];
    onCheckBoxClick: (
        item: PIMFilterGroup | PIMFilterCollection | PIMFilterFeature,
        checked: boolean,
        featureValue?: string
    ) => void;
    featureValues?: string[];
    filterType?: FiltersConst;
}

function MaterialPickerFilter(props: MaterialPickerFilterProps) {

    // debug info 06.01.25
    // console.log('[', props.filterName, ']:');
    // console.log(props.filterArray);

    return (
        <Accordion sx={style.accordion}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography sx={{ fontWeight: 'bold' }}>
                    {props.filterName}
                </Typography>
            </AccordionSummary>

            <AccordionDetails
                sx={style.accordionDetails}
                className="vispak-scrollbar"
            >
                {props.featureValues
                    ? props.featureValues.map((value) => (
                          <FormGroup key={value}>
                              <FormControlLabel
                                  control={
                                      <Checkbox
                                          checked={props.selectedFeatureValues?.includes(
                                              value
                                          )}
                                          onChange={(event) =>
                                              props.onCheckBoxClick(
                                                  // if featureValues are provided then
                                                  // filterArray is PIMFilterFeature[]
                                                  // where there is only one feature
                                                  // because the onClicked checkbox can
                                                  // only be grouped under one feature
                                                  props.filterArray[0],
                                                  event.target.checked,
                                                  value
                                              )
                                          }
                                          sx={{
                                              '& .MuiSvgIcon-root': {
                                                  fontSize: 18,
                                                  color: '#000',
                                              },
                                          }}
                                      />
                                  }
                                  label={value}
                              />
                          </FormGroup>
                      ))
                    : props.filterArray.map((category) => (
                          <FormGroup key={category.id}>
                              <FormControlLabel
                                  control={
                                      <Checkbox
                                          checked={props.selectedFilterGroups.includes(
                                              category.id
                                          )}
                                          onChange={(event) =>
                                              props.onCheckBoxClick(
                                                  category,
                                                  event.target.checked
                                              )
                                          }
                                          sx={{
                                              '& .MuiSvgIcon-root': {
                                                  fontSize: 18,
                                                  color: '#000',
                                              },
                                          }}
                                      />
                                  }
                                  label={category.name}
                              />
                          </FormGroup>
                      ))}
            </AccordionDetails>
        </Accordion>
    );
}

// Memoize the component with custom comparison to improve performance
export default React.memo(MaterialPickerFilter, (prevProps, nextProps) => {
    return (
        prevProps.filterName === nextProps.filterName &&
        prevProps.selectedFilterGroups?.length ===
            nextProps.selectedFilterGroups?.length &&
        prevProps.selectedFeatureValues?.length ===
            nextProps.selectedFeatureValues?.length &&
        JSON.stringify(prevProps.filterArray) ===
            JSON.stringify(nextProps.filterArray)
    );
});

const style = {
    accordion: {
        boxShadow: 'none',
        borderRadius: '0px',
        flexDirection: 'row-reverse',
        padding: '0',
        position: 'static',
        '&.MuiPaper-root': {
            boxShadow: 'none',
        },
        '&:before': {
            display: 'none',
        },

        '& .MuiAccordionSummary-expandIconWrapper': {
            transform: 'rotate(-90deg)',
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            color: colors.orange,
            transform: 'rotate(-180deg)',
        },
        '& .MuiAccordionSummary-root': {
            padding: 0,
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: 0,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            padding: '0',
            margin: '0',
            color: colors.orange,
        },

        fontWeight: 600,
    },
    accordionDetails: {
        padding: 0,
        width: '100%',
        maxHeight: '10rem',
        overflowY: 'auto',
    },
};

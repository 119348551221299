import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { PlateType } from './types';

export interface MoodboardState {
    plates: PlateType[];
    variationSelected: Variation | null; // not to be saved in db
    unsavedChanges: boolean;
}

const initialState: MoodboardState = {
    variationSelected: null,
    plates: [],
    unsavedChanges: false,
};

const moodboardSlice = createSlice({
    name: 'moodboard',
    initialState,
    reducers: {
        setVariationSelected: (
            state: MoodboardState,
            action: PayloadAction<Variation | null>
        ) => {
            state.variationSelected = action.payload;
        },
        updatePlates: (state, action: PayloadAction<PlateType[]>) => {
            state.plates = action.payload;
        },
        updatePlateById: (
            state: MoodboardState,
            action: PayloadAction<{ id: string; updatedPlate: PlateType }>
        ) => {
            const { id, updatedPlate } = action.payload;
            const index = state.plates.findIndex((plate) => plate.id === id);
            if (index !== -1) {
                state.plates[index] = updatedPlate;
            }
        },
        setUnsavedChanges: (
            state: MoodboardState,
            action: PayloadAction<boolean>
        ) => {
            state.unsavedChanges = action.payload;
        },

        clearVariationSelected: (state: MoodboardState) => {
            state.variationSelected = null;
        },
    },
});

// actions

export const {
    updatePlates,
    updatePlateById,
    setVariationSelected,
    setUnsavedChanges,
    clearVariationSelected,
} = moodboardSlice.actions;

// selectors

export const selectVariationSelected = (state: RootState) =>
    state.moodboard.variationSelected;

export const selectPlates = (state: RootState) => state.moodboard.plates;

export const selectUnsavedMoodboardChanges = (state: RootState) =>
    state.moodboard.unsavedChanges;

export default moodboardSlice.reducer;

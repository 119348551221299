import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { LinkProps as MuiLinkProps } from '@mui/material/Link';

export interface LinkProps extends Omit<MuiLinkProps, 'color'> {
    to: string;
    color?: string;
}

const Link = (props: LinkProps) => {
    const theme = useTheme();
    const { to, onClick, sx, color, children, ...rest } = props;

    return (
        <RouterLink to={to} onClick={onClick} {...rest}>
            <span
                style={{
                    ...style,
                    ...sx,
                    color: color ?? theme.extra.text.color.secondary,
                }}
            >
                {children}
            </span>
        </RouterLink>
    );
};

export default Link;

const style: any = {
    textDecoration: 'underline',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'left',
};

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// mui
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Button,
    Stack,
    CircularProgress,
    Box,
    Typography,
    Radio,
    FormControl,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';

// store
import { selectCurrentLanguage } from 'features/localization/state/localizationState';
import {
    fetchProjectById,
    fetchProjects,
    selectProjects,
} from 'features/projects/state/projectState';

// services
import { VersionsService } from 'services/VersionsService';

// config
import { DEFAULT_PAGINATION_LIMIT } from 'config';
import { colors } from 'theming/colors';

// types
import { AppDispatch } from 'store';
import { appPath } from 'routes';
import { VispakOfferStatusConst } from 'types/StatusTypes';

export interface VersionDialogProps {
    projectId: string;
    isVersionDialogOpen: boolean;
    onCloseVersionDialog: () => void;
    showArchived: boolean;
    showTimeCritical: boolean;
}

export default function VersionDialog(props: VersionDialogProps) {
    const theme = useTheme();
    const { t: translate } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const location = useLocation();

    // local state

    // for showing progress when rolling back to some previous version
    const [isRollingBackInProgress, setIsRollingBackInProgress] =
        useState<boolean>(false);
    // for auto selecting the most recent version id or user selected version
    const [selectedVersionId, setSelectedVersionId] = useState<string | null>(
        null
    );

    const [rollbackError, setRollbackError] = useState<string | null>(null);
    const [rollbackWarning, setRollbackWarning] = useState<string | null>(null);
    const [rollbackSuccess, setRollbackSuccess] = useState<string | null>(null);

    // properties

    const { projectId } = props;
    const projects: PaginatedVisualOfferings = useSelector(selectProjects);

    const selectedVisualOffering = useMemo(() => {
        return (
            (projectId &&
                projects.docs.find((project) => project.id === projectId)) ||
            null
        );
    }, [projectId, projects]);

    const selectedLocale = useSelector(selectCurrentLanguage);

    const sortedAndFilteredVersions = useMemo(() => {
        if (!selectedVisualOffering) return [];

        return (
            [...(selectedVisualOffering?.versionConstructs ?? [])]
                // sort by version date descending (most recent first)
                ?.sort((a, b) => b.versionDate.localeCompare(a.versionDate))
            // remove versions that are within 30 seconds of each other
            // quick fix to remove most of the versions which have wrong
            // snapshot id.
            // It's because when saving variation, multiple requests
            // are made and each one gets a new version but not all versions
            // have the same correct snapshot id - like uploading a picture
            // has a new version but that version has a variation where snapshot
            // is not yet bound to variation (with updateVariation service).
            // .reduce(
            //     (
            //         acc: typeof selectedVisualOffering.versionConstructs,
            //         current
            //     ) => {
            //         const currentTimestamp = new Date(
            //             current.versionDate
            //         ).getTime();
            //         const hasNearbyVersion = acc.some(
            //             (version) =>
            //                 Math.abs(
            //                     new Date(version.versionDate)?.getTime() -
            //                         currentTimestamp
            //                 ) < 30000
            //         );
            //         if (!hasNearbyVersion) {
            //             acc.push(current);
            //         }
            //         return acc;
            //     },
            //     []
            // )
        );
    }, [selectedVisualOffering]);

    // features

    const clearRollbackMessages = () => {
        setRollbackError(null);
        setRollbackWarning(null);
        setRollbackSuccess(null);
    };

    const onRollbackToVersion = async () => {
        setIsRollingBackInProgress(true);

        const isOffersPath = location?.pathname === appPath.projects;
        const isSingleOfferPath = location?.pathname.includes(
            appPath.project.replace(':projectId', projectId)
        );

        // clear previous messages
        clearRollbackMessages();

        const versionsService = new VersionsService();

        if (selectedVisualOffering) {
            const selectedVersion: VersionConstruct | undefined =
                selectedVisualOffering.versionConstructs?.find(
                    (v) => v.id === selectedVersionId
                );

            if (selectedVersion) {
                // get previous version data to check if it's available
                const previousVersion: VisualOffering | undefined =
                    await versionsService
                        .getPreviousVersion({
                            visualOfferingVersionId:
                                selectedVersion.visualOfferingVersionId,
                            versionConstructId: selectedVersion.id,
                        })
                        .catch((error) => {
                            console.error(
                                'Error getting previous version:',
                                error
                            );
                            // show error
                            setRollbackError(
                                translate(
                                    'component.versionDialog.rollbackError'
                                )
                            );
                            return undefined;
                        });

                if (previousVersion) {
                    // roll back to that version
                    const rollingBackResponse = await versionsService
                        .rollBackToPreviousVersion(selectedVersion.id)
                        .catch((error) => {
                            console.error(
                                'Error rolling back to previous version:',
                                error
                            );
                            // show error
                            setRollbackError(
                                translate(
                                    'component.versionDialog.rollbackError'
                                )
                            );
                            return undefined;
                        });

                    // success, fetch new projects
                    if (rollingBackResponse === 200) {
                        if (isOffersPath) {
                            await getNewProjects();
                        }

                        if (isSingleOfferPath) {
                            await getUpdatedProject();
                        }

                        // show success
                        setRollbackSuccess(
                            translate('component.versionDialog.rollbackSuccess')
                        );

                        scrollVersionsContainerToTop();
                    } else {
                        console.error(
                            'WARNING: Rolling back response has changed to something other than 200'
                        );

                        // show warning
                        setRollbackWarning(
                            translate('component.versionDialog.rollbackWarning')
                        );
                    }
                }
            }
        }

        setIsRollingBackInProgress(false);
    };

    const onCloseVersionDialog = () => {
        setSelectedVersionId(null);
        clearRollbackMessages();
        props.onCloseVersionDialog();
    };

    // for project list
    const getNewProjects = async () => {
        await dispatch(
            fetchProjects({
                page: 1,
                limit: props.showArchived || props.showTimeCritical
                    ? 100
                    : DEFAULT_PAGINATION_LIMIT,
                status: props.showArchived
                    ? VispakOfferStatusConst.ARCHIVED
                    : undefined,
                hasReminderDate: props.showTimeCritical ? true : undefined,
            })
        );
    };

    const getUpdatedProject = async () => {
        // fetch currently selected project data
        if (selectedVisualOffering?.id) {
            dispatch(fetchProjectById(selectedVisualOffering.id));
        }
    };

    const scrollVersionsContainerToTop = () => {
        const versionsContainer = document.querySelector(
            '[data-versions-container]'
        );
        versionsContainer?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // use effects

    useEffect(() => {
        if (sortedAndFilteredVersions.length) {
            // find the most recent version from sortedAndFilteredVersions
            // NB! In BE the the most recent version the Visual Offer has
            // is always selected by default. i.e. when rolling back
            // to previous version, the new version is created and it's also
            // the most recent
            setSelectedVersionId(sortedAndFilteredVersions[0].id);
        }

        return () => {
            setSelectedVersionId(null);
        };
    }, [sortedAndFilteredVersions]);

    return (
        <Dialog open={props.isVersionDialogOpen} onClose={onCloseVersionDialog}>
            {/* Right top close button */}
            <IconButton
                aria-label="close"
                onClick={onCloseVersionDialog}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.main,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Title */}
            <DialogTitle sx={styles.dialogTitle}>
                {translate(`component.versionDialog.title`)}
            </DialogTitle>

            {/* Content */}
            <DialogContent sx={styles.dialogContent}>
                <Stack direction="row">
                    <Stack
                        sx={{
                            width: {
                                xs: '25rem', // 0 - 599 px
                                sm: '30rem', // 600 - 899 px
                                md: '35rem', // 900 - 1199px
                                lg: '36rem', // 1200 - 1535px
                                xl: '36rem', // 1536+ px
                            },
                        }}
                        direction="column"
                    >
                        <Box component="div" sx={styles.contentBox}>
                            {/* Visual offer name (for showing versions to) */}
                            <Box component="div" sx={styles.contentSection}>
                                <Typography sx={styles.contentSectionTitle}>
                                    {translate(
                                        `component.versionDialog.visualOfferingName`
                                    )}
                                </Typography>

                                <Typography sx={styles.contentSectionText}>
                                    {selectedVisualOffering?.name}
                                </Typography>
                            </Box>

                            {/* Versions list */}
                            {selectedVisualOffering && (
                                <Box component="div" sx={styles.contentSection}>
                                    <Box
                                        component="div"
                                        id="rollback-error-container"
                                    >
                                        {rollbackError && (
                                            <Typography
                                                color={colors.red}
                                                sx={styles.errorText}
                                            >
                                                {rollbackError}
                                            </Typography>
                                        )}
                                        {rollbackSuccess && (
                                            <Typography
                                                color={colors.green}
                                                sx={styles.successText}
                                            >
                                                {rollbackSuccess}
                                            </Typography>
                                        )}
                                        {rollbackWarning && (
                                            <Typography
                                                color={colors.orange}
                                                sx={styles.warningText}
                                            >
                                                {rollbackWarning}
                                            </Typography>
                                        )}
                                    </Box>
                                    {/* Table Header */}
                                    <Box
                                        component="div"
                                        sx={styles.tableHeader}
                                    >
                                        <Typography
                                            sx={styles.tableHeaderCellFirst}
                                        >
                                            {translate(
                                                `component.versionDialog.description`
                                            )}
                                        </Typography>
                                        <Typography sx={styles.tableHeaderCell}>
                                            {translate(
                                                `component.versionDialog.rooms`
                                            )}
                                        </Typography>
                                        <Typography sx={styles.tableHeaderCell}>
                                            {translate(
                                                `component.versionDialog.variations`
                                            )}
                                        </Typography>
                                    </Box>
                                    <Box
                                        component="div"
                                        sx={styles.tableBody}
                                        data-versions-container
                                    >
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="versions-radio-buttons-group-label"
                                                value={selectedVersionId || ''}
                                                onChange={(e) => {
                                                    const versionId =
                                                        e.target.value;
                                                    setSelectedVersionId(
                                                        versionId
                                                    );
                                                }}
                                                name="version-dialog-radio-group"
                                            >
                                                {sortedAndFilteredVersions.map(
                                                    (version) => (
                                                        <Box
                                                            component="div"
                                                            key={version.id}
                                                            sx={styles.tableRow}
                                                        >
                                                            <FormControlLabel
                                                                value={
                                                                    version.id
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label={
                                                                    new Date(
                                                                        version.versionDate
                                                                    )?.toLocaleString(
                                                                        selectedLocale,
                                                                        {
                                                                            dateStyle:
                                                                                'short',
                                                                            timeStyle:
                                                                                'medium',
                                                                        }
                                                                    ) || ''
                                                                }
                                                                sx={
                                                                    styles.tableCellFirst
                                                                }
                                                            />
                                                            <Typography
                                                                sx={
                                                                    styles.tableCell
                                                                }
                                                            >
                                                                {version.rooms
                                                                    ?.length ||
                                                                    0}
                                                            </Typography>
                                                            <Typography
                                                                sx={
                                                                    styles.tableCell
                                                                }
                                                            >
                                                                {version
                                                                    .variations
                                                                    ?.length ||
                                                                    0}
                                                            </Typography>
                                                        </Box>
                                                    )
                                                )}
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Stack>
                </Stack>
            </DialogContent>

            {/* Actions */}
            <DialogActions sx={styles.dialogActions}>
                <Button
                    sx={styles.buttonCancel}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={onCloseVersionDialog}
                >
                    {translate(`cancel`)}
                </Button>
                <Button
                    sx={styles.buttonSubmit}
                    variant={'contained'}
                    type="submit"
                    disabled={
                        isRollingBackInProgress ||
                        !selectedVersionId ||
                        sortedAndFilteredVersions?.[0]?.id === selectedVersionId
                    }
                    onClick={onRollbackToVersion}
                >
                    {isRollingBackInProgress ? (
                        <CircularProgress size={20} />
                    ) : (
                        translate(`component.versionDialog.rollback`)
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const styles = {
    dialogTitle: {
        fontSize: '1.8rem',
        mb: '1rem',
    },
    dialogContent: {
        padding: '1rem 1.8rem',
    },
    dialogActions: {
        justifyContent: 'flex-end',
        gap: '0.4rem',
        margin: '0px 0.5rem 0.5rem 0px',
    },
    buttonCancel: {
        height: '2.8rem',
    },
    buttonSubmit: {
        height: '2.8rem',
        minWidth: '10rem',
    },

    // content ui elements
    contentBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    contentSection: {
        display: 'flex',
        flexDirection: 'column',
        mb: '',
    },
    contentSectionTitle: {
        fontWeight: 'bold',
        fontSize: '1rem',
        mb: '0.5rem',
    },
    contentSectionText: {
        fontWeight: 'normal',
        fontSize: '1.2rem',
        mb: '0.5rem',
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        mb: '0.5rem',
        fontWeight: 'bold',
    },
    tableHeaderCell: {
        flex: 1,
        fontSize: '1rem',
        textAlign: 'center',
    },
    tableHeaderCellFirst: {
        flex: 2,
    },
    tableBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        maxHeight: '14rem',
        overflowY: 'auto',
        borderTop: `1px solid ${colors.fGrey}`,
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem',
        borderBottom: `1px solid ${colors.fGrey}`,
    },
    tableCell: {
        flex: 1,
        textAlign: 'center',
    },
    tableCellFirst: {
        flex: 2,
    },
    errorText: {
        fontSize: '1rem',
        mb: '0.5rem',
    },
    successText: {
        fontSize: '1rem',
        mb: '0.5rem',
    },
    warningText: {
        fontSize: '1rem',
        mb: '0.5rem',
    },
};

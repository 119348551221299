import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { appPath } from 'routes';
import LazyLoad from 'react-lazy-load';

// mui
import Paper from '@mui/material/Paper';
import { Box, Button, Stack, CircularProgress } from '@mui/material';

// ui
import Body from 'components/base/text/Body';
import Tag from 'components/base/Tag';
import CommentButton from 'components/CommentButton';
import StatusTag from 'components/StatusTag';
import RoomMenu from 'features/projects/components/RoomMenu';
import DeleteRoomDialog from 'components/specific/project/DeleteRoomDialog';
import ModifyRoomDialog from 'components/specific/project/ModifyRoomDialog';

// store
import {
    selectProjects,
    selectSelectedProject,
    updateRoomThunk,
    selectIsLoading,
    setCompareWithVariation,
    selectDesignCodeProject,
} from 'features/projects/state/projectState';

// services
import { PIMService } from 'services/PIMService';

// config
import { colors } from 'theming/colors';
import { PLACEHOLDER_IMAGE_URL, COMPARE_VIEW_BLANK_IMAGE_URL } from 'config';

// types
import { AppDispatch } from 'store';
import {
    VispakOfferStatus,
    VispakRoomStatus,
    VispakRoomStatusConst,
} from 'types/StatusTypes';

export interface RoomProps {
    id: string;
    name: string;
    roomImageUrl: string;
    variations?: Variation[];
    comments: UserComment[];
    status: Partial<VispakOfferStatus>;
    solutionId: string;
}

const Room = (props: RoomProps) => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    // Local State

    const [isDeleteRoomDialogOpen, setIsDeleteRoomDialogOpen] = useState(false);
    const [isModifyRoomDialogOpen, setIsModifyRoomDialogOpen] = useState(false);
    const [fetchedSnapshotUrl, setFetchedSnapshotUrl] = useState<string>('');
    const [imageLoading, setImageLoading] = useState<boolean>(true);

    // Properties

    const designCodeProject = useSelector(selectDesignCodeProject);

    const baseUrl = window.location.origin;
    const projects = useSelector(selectProjects);
    const selectedProject = useSelector(selectSelectedProject);
    const foundModifiableProject = projects.docs.find(
        (project) => project.id === selectedProject?.id
    );
    const foundSolution = foundModifiableProject?.spacialSolutions?.find(
        (solution: SpacialSolution) => solution.id === props.solutionId
    );
    const foundThisRoom = foundSolution?.rooms?.find(
        (room: Room) => room.id === props.id
    );
    const isLoading = useSelector(selectIsLoading);

    const firstVariation = props.variations?.[0] || null;

    // Features

    const toggleRoomStatus = () => {
        const currentRoomStatus: VispakRoomStatus | undefined =
            foundThisRoom?.status;
        const toggledRoomStatuses: { [key: string]: VispakRoomStatus } = {
            [VispakRoomStatusConst.IN_PROGRESS]: VispakRoomStatusConst.PUBLIC,
            [VispakRoomStatusConst.PUBLIC]: VispakRoomStatusConst.IN_PROGRESS,
        };
        const getToggledStatus: VispakRoomStatus | undefined =
            currentRoomStatus && toggledRoomStatuses[currentRoomStatus];

        if (getToggledStatus) {
            const updateData: Partial<Room> = { status: getToggledStatus };
            dispatch(updateRoomThunk({ id: props.id, updateData }));
        }
    };

    const processCurrentVariationThumbnail = useCallback(async () => {
        setImageLoading(true);

        // newly created room
        if (!props.variations?.length) {
            setFetchedSnapshotUrl(COMPARE_VIEW_BLANK_IMAGE_URL);
            setImageLoading(false);
            return;
        }

        const pimService = new PIMService();

        // Find the snapshot with the most recent date
        // for the first variation only because room has multiple variations
        // and in solution group only one variation can be shown on room card
        const latestSnapshot = props.variations?.[0]?.snapshots?.reduce(
            (latest: Snapshot | null, current: Snapshot) => {
                const currentDate = new Date(current.updatedAt);
                const latestDate = new Date(latest?.updatedAt || 0);
                return currentDate > latestDate ? current : latest;
            },
            null
        );

        // go through all sizes to get whichever is available starting
        // from smaller size
        const latestSnaphotUrl =
            latestSnapshot?.sizes?.thumbnail?.url ||
            latestSnapshot?.sizes?.medium?.url ||
            latestSnapshot?.sizes?.large?.url ||
            latestSnapshot?.url ||
            '';

        // no snapshot found (newly created room but possibly empty variation)
        if (!latestSnaphotUrl) {
            setFetchedSnapshotUrl(COMPARE_VIEW_BLANK_IMAGE_URL);
            setImageLoading(false);
            return;
        }

        try {
            if (designCodeProject) {
                const designCodeParameter = `?designcode=${designCodeProject.designCode}`;
                const image = await pimService.getImage(
                    latestSnaphotUrl + designCodeParameter
                );
                if (image) {
                    setFetchedSnapshotUrl(URL.createObjectURL(image));
                } else {
                    setFetchedSnapshotUrl(`${baseUrl}${PLACEHOLDER_IMAGE_URL}`);
                }
            } else {
                const image = await pimService.getImage(latestSnaphotUrl);
                if (image) {
                    setFetchedSnapshotUrl(URL.createObjectURL(image));
                } else {
                    setFetchedSnapshotUrl(`${baseUrl}${PLACEHOLDER_IMAGE_URL}`);
                }
            }
        } catch (error) {
            console.error('Error fetching image:', error);
            setFetchedSnapshotUrl(`${baseUrl}${PLACEHOLDER_IMAGE_URL}`);
        } finally {
            setImageLoading(false);
        }

        // only run once otherwise every project view action will trigger
        // this new image fetching side effect and UI will flicker unnecessarily
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRoomClick = () => {
        // Navigate to room view 'RoomView.tsx'
        navigate(appPath.room.replace(':roomId', props.id));
    };

    const onRoomCompare = () => {
        if (firstVariation) {
            const compareWithVariationIDSet = dispatch(
                setCompareWithVariation(firstVariation.id)
            );

            if (compareWithVariationIDSet.payload === firstVariation.id) {
                navigate(appPath.comparison);
            }
        }
    };

    // side effects
    useEffect(() => {
        processCurrentVariationThumbnail();
    }, [processCurrentVariationThumbnail]);

    return (
        <Paper
            sx={{
                ...style.paper,
                boxShadow: 'none',
                border: 'solid',
            }}
            style={{ borderColor: '#e5e6e8', borderWidth: 2 }}
        >
            {/* Header Section */}
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={style.header}
            >
                <Body sx={style.name}>{props.name}</Body>
                <RoomMenu
                    handleDeleteRoom={() => setIsDeleteRoomDialogOpen(true)}
                    handleModifyRoom={() => setIsModifyRoomDialogOpen(true)}
                    handleToggleRoomStatus={() => toggleRoomStatus()}
                    handleCompareRoom={() => onRoomCompare()}
                />
            </Stack>

            {/* Image Section */}
            <Button
                sx={style.imageButton}
                disabled={isLoading}
                onClick={onRoomClick}
                TouchRippleProps={{ style: { color: colors.fGrey } }}
            >
                <Box component="div" sx={style.roomImageBox}>
                    {imageLoading ? (
                        <Box component="div" sx={style.circularLoaderBox}>
                            <CircularProgress size={20} />
                        </Box>
                    ) : (
                        <LazyLoad>
                            <img
                                src={fetchedSnapshotUrl}
                                alt={`Snapshot of ${props.name}`}
                                onLoad={() => setImageLoading(false)}
                                onError={(
                                    e: React.SyntheticEvent<
                                        HTMLImageElement,
                                        Event
                                    >
                                ) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = `${baseUrl}${PLACEHOLDER_IMAGE_URL}`;
                                }}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                    display: 'block',
                                }}
                            />
                        </LazyLoad>
                    )}
                </Box>
            </Button>

            {/* Footer Section */}
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={style.footer}
            >
                <StatusTag sx={style.statusTag} status={props.status} />
                <Stack direction="row" alignItems="center" spacing={1}>
                    <CommentButton
                        sx={style.commentButton}
                        comments={props.comments}
                    />
                    <Tag
                        sx={{ ...style.versionsTag, maxHeight: '25px' }}
                        color="#dddddd"
                    >
                        {props.variations?.length}
                    </Tag>
                </Stack>
            </Stack>

            {/* Modals */}
            <DeleteRoomDialog
                currentRoom={foundThisRoom}
                roomId={props.id}
                isDeleteRoomDialogOpen={isDeleteRoomDialogOpen}
                onCloseDeleteRoomDialog={() => {
                    setIsDeleteRoomDialogOpen(false);
                }}
            />
            <ModifyRoomDialog
                currentRoom={foundThisRoom}
                roomId={props.id}
                isModifyRoomDialogOpen={isModifyRoomDialogOpen}
                onCloseModifyRoomDialog={() => {
                    setIsModifyRoomDialogOpen(false);
                }}
            />
        </Paper>
    );
};

export default Room;

// Constants
const padding = '0.75rem';
const buttonPadding = '0.5rem';

// Styles
const style: any = {
    paper: {
        padding: padding,
        width: '240px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
    },
    header: {
        marginBottom: '0.5rem',
        height: '40px', // Fixed height for header
    },
    name: {
        fontWeight: 700,
        fontSize: '1rem',
        display: 'block',
        position: 'relative',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginTop: buttonPadding,
        width: 'auto',
    },
    roomImageBox: {
        width: '100%',
        aspectRatio: '16 / 9', // Maintains 16:9 aspect ratio for the image box
        position: 'relative',
        overflow: 'hidden',
        marginBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageButton: {
        padding: 0,
        // Removed flexGrow to prevent the image from expanding infinitely
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footer: {
        marginTop: '0.5rem',
        height: '40px', // Fixed height for footer
    },
    versionsTag: {
        fontWeight: 700,
        padding: '0.25rem 0.5rem',
        fontSize: '0.875rem',
    },
    statusTag: {
        fontSize: '0.875rem',
    },
    commentButton: {
        fontSize: '0.875rem',
    },
    circularLoaderBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
};

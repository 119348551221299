import React, { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { OrthographicCamera } from '@react-three/drei';

interface CameraControllerProps {
    layersCamera: boolean;
}

const CameraController: React.FC<CameraControllerProps> = ({ layersCamera }) => {
    const { camera } = useThree();
    
    // define zoom limits
    const MIN_ZOOM = 100;
    const MAX_ZOOM = 500;
    const DEFAULT_ZOOM = 250;

    // enforce zoom limits whenever camera zoom changes
    useEffect(() => {
        const enforceZoomLimits = () => {
            if (camera.zoom < MIN_ZOOM) {
                camera.zoom = MIN_ZOOM;
                camera.updateProjectionMatrix();
            } else if (camera.zoom > MAX_ZOOM) {
                camera.zoom = MAX_ZOOM;
                camera.updateProjectionMatrix();
            }
        };

        enforceZoomLimits();
    }, [camera.zoom, camera]);

    useEffect(() => {
        if (!layersCamera) {
            // reset to top-down view
            camera.position.set(0, 50, 0);
            camera.rotation.set(-Math.PI / 2, 0, 0);
            camera.zoom = DEFAULT_ZOOM;
            camera.updateProjectionMatrix();
            // ensure quaternion matches euler rotation
            camera.quaternion.setFromEuler(camera.rotation);
        } else {
            // set to layers view
            camera.position.set(2, 2, 26);
            camera.rotation.set(-Math.PI / 4, Math.PI / 4, 0);
            camera.zoom = DEFAULT_ZOOM;
            camera.updateProjectionMatrix();
            // ensure quaternion matches euler rotation
            camera.quaternion.setFromEuler(camera.rotation);
        }
    }, [camera, layersCamera]);

    return (
        <OrthographicCamera
            makeDefault
            position={layersCamera ? [2, 2, 26] : [0, 50, 0]}
            zoom={DEFAULT_ZOOM}
            rotation={
                layersCamera
                    ? [-Math.PI / 4, Math.PI / 4, 0]
                    : [-Math.PI / 2, 0, 0]
            }
        />
    );
};

export default CameraController;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// ui
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// store
import {
    setGlobalModalOfferSend,
    selectProjects,
} from 'features/projects/state/projectState';

// helpers
import { visualOfferHasAnyPublicRooms } from 'utils';

// types
import { VispakOfferStatusConst } from 'types/StatusTypes';
export interface ProjectListOptionsMenuProps
    extends Omit<MenuProps, 'onClose'> {
    activeProjectId: string;
    setIsModifyProjectDialogOpen: () => void;
    setIsDeleteProjectDialogOpen: () => void;
    setIsArchiveProjectDialogOpen: () => void;
    setIsVersionDialogOpen: () => void;
    setIsDuplicateProjectDialogOpen: () => void;
    onClose: () => void;
    className: string;
    open: boolean;
    anchorEl: HTMLElement | null;
    anchorOrigin: {
        vertical: number | 'center' | 'top' | 'bottom';
        horizontal: number | 'center' | 'left' | 'right';
    };
    transformOrigin: {
        vertical: number | 'center' | 'top' | 'bottom';
        horizontal: number | 'center' | 'left' | 'right';
    };
}

const ProjectListOptionsMenu = (props: ProjectListOptionsMenuProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // properties

    const projects = useSelector(selectProjects);
    const currentVisualOffering = projects.docs.find(
        (project) => project.id === props.activeProjectId
    );
    const isPublicStatus =
        currentVisualOffering?.status === VispakOfferStatusConst.PUBLIC;
    const isArchivedStatus =
        currentVisualOffering?.status === VispakOfferStatusConst.ARCHIVED;
    const isArchivingDisabled = !isPublicStatus && !isArchivedStatus;

    // strip extra arguments from props
    // to please the 'Menu' MUI component - clone object except one key
    let {
        setIsModifyProjectDialogOpen: _,
        setIsDeleteProjectDialogOpen: __,
        setIsArchiveProjectDialogOpen: ___,
        setIsVersionDialogOpen: _____,
        activeProjectId: ______,
        setIsDuplicateProjectDialogOpen: _______,
        ...strippedProps
    } = props;

    // features

    const onChangeDetailsClick = () => {
        props.onClose();
        props.setIsModifyProjectDialogOpen();
    };

    const onPreviousVersionsClick = () => {
        props.setIsVersionDialogOpen();
        props.onClose();
    };

    const onShareClick = () => {
        // for shared component <SendOfferDialog />
        dispatch(
            setGlobalModalOfferSend({ open: true, id: props.activeProjectId })
        );
        props.onClose();
    };

    const onArchiveClick = () => {
        props.setIsArchiveProjectDialogOpen();
        props.onClose();
    };

    const onDeleteClick = async () => {
        props.onClose();
        props.setIsDeleteProjectDialogOpen();
    };

    const onDuplicateClick = () => {
        props.setIsDuplicateProjectDialogOpen();
        props.onClose();
    };

    return (
        <Menu {...strippedProps} onClose={props.onClose}>
            <MenuItem onClick={onChangeDetailsClick}>
                {t(`component.projectListRowOptions.changeDetails`)}
            </MenuItem>
            <MenuItem
                disabled={isArchivedStatus}
                onClick={onPreviousVersionsClick}
            >
                {t(`component.projectListRowOptions.previousVersions`)}
            </MenuItem>
            <MenuItem
                disabled={!visualOfferHasAnyPublicRooms(currentVisualOffering)}
                onClick={onShareClick}
            >
                {t(`share`)}
            </MenuItem>
            <MenuItem disabled={isArchivingDisabled} onClick={onArchiveClick}>
                {isArchivedStatus ? t(`dearchive`) : t(`archive`)}
            </MenuItem>
            <MenuItem onClick={onDuplicateClick}>{t(`duplicate`)}</MenuItem>
            <MenuItem onClick={onDeleteClick}>{t(`delete`)}</MenuItem>
        </Menu>
    );
};

export default ProjectListOptionsMenu;

import React, { useState, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appPath } from 'routes';

// mui
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SettingsIcon from '@mui/icons-material/Settings';

// ui
import Body from './base/text/Body';

// store
import { clearUserDetails } from 'features/authentication/state/authState';
import {
    setDesignCodeProject,
    clearProjects,
} from 'features/projects/state/projectState';

// services
import { VispakUsersService } from '../services/vispakUsers/VispakUsersService';

const vispakUsersService = new VispakUsersService();

export interface AccountProps {
    designCodeProject: VisualOffering | undefined;
    logoutText: string;
    settingsText: string;
    username?: string;
}

const Account = (props: AccountProps) => {
    const [menuTarget, setMenuTarget] = useState<null | HTMLElement>(null);
    const open = Boolean(menuTarget);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = async () => {
        if (!props.designCodeProject) {
            const logoutResponse =
                await vispakUsersService.invalidateAuthToken();
            if (logoutResponse) {
                // clear store
                dispatch(clearUserDetails());
                dispatch(clearProjects());

                navigate(appPath.login);
            }
        } else {
            dispatch(setDesignCodeProject(null));
            navigate(appPath.designCode);
        }
    };

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setMenuTarget(event.currentTarget);
    };
    const handleClose = () => {
        setMenuTarget(null);
    };
    const doSomething = () => {
        handleClose();
    };

    if (!props.username) return <></>;

    return (
        <>
            <button onClick={handleClick} style={{ ...style.button }}>
                <AccountCircleOutlinedIcon />
                <Body sx={{ marginLeft: '1rem' }}>{props.username}</Body>
                <ArrowDropDownIcon
                    sx={{
                        ...style.dropdown,
                        transform: open ? 'rotate(180deg)' : '',
                    }}
                />
            </button>
            <Menu
                open={open}
                anchorEl={menuTarget}
                onClick={doSomething}
                onClose={handleClose}
            >
                {!props.designCodeProject && (
                    <MenuItem>
                        <SettingsIcon />
                        <Body> {props.settingsText}</Body>
                    </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                    <LogoutIcon />
                    <Body> {props.logoutText}</Body>
                </MenuItem>
            </Menu>
        </>
    );
};

export default Account;

const style: any = {
    button: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        border: '0',
        background: 'transparent',
        textTransform: 'none',
    },
    dropdown: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
};

import { PivotConfig, ToolType } from './types';

export const pivotConfigs: Record<ToolType, PivotConfig> = {
    translate: {
        activeAxes: [true, false, true],
        disableRotations: true,
        disableScaling: true,
        disableSliders: false,
        disableAxes: false,
    },
    rotate: {
        activeAxes: [true, true, true],
        disableRotations: false,
        disableScaling: true,
        disableSliders: false,
        disableAxes: false,
    },
    scale: {
        activeAxes: [true, false, true],
        disableRotations: true,
        disableScaling: false,
        disableSliders: true,
        disableAxes: true,
    },
    crop: {
        activeAxes: [true, true, true],
        disableRotations: true,
        disableScaling: false,
        disableSliders: true,
        disableAxes: true,
    },
    inactive: {
        activeAxes: [false, false, false],
        disableRotations: true,
        disableScaling: true,
        disableSliders: true,
        disableAxes: true,
    },
    delete: {
        activeAxes: [false, false, false],
        disableRotations: false,
        disableScaling: false,
        disableSliders: false,
        disableAxes: false,
    },
    next: {
        activeAxes: [false, false, false],
        disableRotations: false,
        disableScaling: false,
        disableSliders: false,
        disableAxes: false,
    },
    previous: {
        activeAxes: [false, false, false],
        disableRotations: false,
        disableScaling: false,
        disableSliders: false,
        disableAxes: false,
    },
    info: {
        activeAxes: [false, false, false],
        disableRotations: false,
        disableScaling: false,
        disableSliders: false,
        disableAxes: false,
    },
    moveUp: {
        activeAxes: [false, false, false],
        disableRotations: false,
        disableScaling: false,
        disableSliders: false,
        disableAxes: false,
    },
    moveDown: {
        activeAxes: [false, false, false],
        disableRotations: false,
        disableScaling: false,
        disableSliders: false,
        disableAxes: false,
    },
    lock: {
        activeAxes: [false, false, false],
        disableRotations: true,
        disableScaling: true,
        disableSliders: true,
        disableAxes: true,
    },
    // reset button removed
};

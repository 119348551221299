import axios, { AxiosResponse } from 'axios';
import { API_URL, AUTH_COOKIE_NAME } from 'config';
import { CookieService } from 'services/cookie/CookieService';

export class EmailService {
    public url;
    public token;
    public config;

    constructor() {
        this.url = `${API_URL}/v0/email/visualOffering`;
        this.token = CookieService.getCookieByName(AUTH_COOKIE_NAME);
        this.config = {
            headers: {
                Authorization: `Bearer ${this.token}`,
            },
        };
    }

    public async postVisualOfferingEmails(
        visualOfferingId: string,
        toEmails: string[],
        customMessage: string
    ): Promise<any> {
        try {
            const response: AxiosResponse<any> = await axios.post(
                this.url,
                {
                    visualOfferingId,
                    toEmails,
                    customMessage,
                },
                this.config
            );

            return response;
        } catch (error: any) {
            throw new Error('Sending email(s) failed:', error?.message);
        }
    }

    public async postSamplesByEmailRequest(
        visualOfferingId: string,
        samples: ProductSampleData[]
    ): Promise<any> {
        try {
            const response: AxiosResponse<any> = await axios.post(
                this.url + '/samples',
                {
                    visualOfferingId,
                    samples,
                },
                this.config
            );

            return response;
        } catch (error: any) {
            throw new Error('Sending samples by email failed:', error?.message);
        }
    }
}

import { Modal, Box, IconButton, Typography, useTheme } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

interface SimpleModalProps {
    isOpen: boolean;
    onClose: () => void;
    alertText: string;
}

export default function NotificationModal(props: SimpleModalProps) {
    const { isOpen, onClose, alertText } = props;
    const theme = useTheme();

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                component={'div'}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 6,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.secondary.main,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography id="modal-title" fontSize={'1.2rem'}>
                    {alertText}
                </Typography>
            </Box>
        </Modal>
    );
}

import React, {
    useState,
    useRef,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AUTH_COOKIE_NAME } from 'config';
import { appPath } from 'routes';
import { generateHashFromTimestamp } from 'utils';

// mui
import { Divider, IconButton, Stack, Box, Typography } from '@mui/material';
import {
    CompareOutlined,
    PhotoCameraOutlined,
    SaveOutlined,
    ExitToAppOutlined,
    RestoreOutlined,
} from '@mui/icons-material';

// ui
import { colors } from 'theming/colors';
import SpatialSwitcher from 'components/specific/configurator/SpatialSwitcher';
import ConfiguratorPanel from 'components/specific/configurator/ConfiguratorPanel';
import MaterialPicker from 'components/specific/configurator/MaterialPicker';
import Moodboard from 'features/moodboard/Moodboard';
import BreadcrumbNavigation from 'components/BreadcrumbNavigation';
import NotificationModal from 'components/base/NotificationModal';
import RenameVariation from 'components/specific/configurator/RenameVariation';
import ErrorFallback from 'components/base/ErrorFallback';

// store
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
    selectProjects,
    selectRoomById,
    createVariationThunk,
    updateVariationThunk,
    selectIsLoading,
    setCompareWithVariation,
    selectDesignCodeProject,
    selectDesignCodeRoomById,
    selectGlobalModals,
    setGlobalModalMoodboardUnsavedChanges,
} from 'features/projects/state/projectState';
import {
    selectPlates,
    updatePlates,
    setVariationSelected,
    clearVariationSelected,
    selectVariationSelected,
    selectUnsavedMoodboardChanges,
    setUnsavedChanges,
} from 'features/moodboard/moodboardState';

// services
import { CookieService } from 'services/cookie/CookieService';
import {
    getTemplatesByLimit,
    createVariation,
    updateVariation,
} from 'services/projectServices';

// types
import { AppDispatch } from 'store';
import { MoodboardState } from 'features/moodboard/moodboardState';
import { PlateType } from 'features/moodboard/types';
import {
    GlobalModalState,
    GlobalModalData,
} from 'features/projects/state/projectState';

const RoomView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    // local state

    const stackRef = useRef<HTMLDivElement>(null); // Create a ref for the Stack component
    // what comes from child component
    const moodboardRef = useRef<{
        takeScreenshot: () => void;
        uploadScreenshot: () => Promise<Variation | null>;
    }>(null);

    const [boxHeight, setBoxHeight] = useState<number>(window.innerHeight);

    const [isclosedConfiguratorPanel, setIsClosedConfiguratorPanel] =
        useState<boolean>(true);

    const [isLastVariationModalOpen, setIsLastVariationModalOpen] =
        useState<boolean>(false);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [isRenameVariationDialogOpen, setIsRenameVariationDialogOpen] =
        useState<boolean>(false);

    const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
        useState<boolean>(false);

    const [
        variationClickedOnSpatialSwitcher,
        setVariationClickedOnSpatialSwitcher,
    ] = useState<Variation | null>(null);

    const [templatesFromDb, setTemplatesFromDb] = useState<Variation[] | null>(
        null
    );

    // properties

    // if set it's a design code session (store is populated with designCodeProject)
    const designCodeProject = useSelector(selectDesignCodeProject);

    const unsavedMoodboardChanges = useSelector(selectUnsavedMoodboardChanges);
    const globalModals: GlobalModalState = useSelector(selectGlobalModals);

    const { roomId } = useParams();
    const authCookie = CookieService.getCookieByName(AUTH_COOKIE_NAME);
    const projects = useSelector(selectProjects).docs;
    const selectedRoomFromRegularSession = useSelector((state: RootState) =>
        selectRoomById(state, roomId!)
    );
    const selectedRoomFromDesignCodeProject = useSelector((state: RootState) =>
        selectDesignCodeRoomById(state, roomId!)
    );

    const selectedRoom = designCodeProject
        ? selectedRoomFromDesignCodeProject
        : selectedRoomFromRegularSession;

    const isLoading = useSelector(selectIsLoading);

    const currentMoodBoardPlates: PlateType[] = useSelector(selectPlates);
    const variationsWithoutSoftDelete = selectedRoom?.variations?.filter(
        (variation) => !variation.deleted
    );
    const selectedVariation: Variation | null = useSelector(
        selectVariationSelected
    );

    const productIds =
        currentMoodBoardPlates
            ?.map((plate) => plate?.pimProductId)
            ?.filter((id): id is string => !!id) ?? [];

    // only used to return early from useEffect side effects
    // navigation is handled in redirectIfNoId
    // TODO: replace with return statements in side effects
    const shouldNavigateAway = useCallback(() => {
        return !designCodeProject && (!authCookie || !projects.length);
    }, [authCookie, projects, designCodeProject]);

    const variationHasNoPlates = useMemo(() => {
        if (!selectedVariation) {
            return false;
        }

        const plates = JSON.parse(selectedVariation.data).plates;
        return !plates?.length;
    }, [selectedVariation]);

    const isSaveButtonDisabled = useMemo(() => {
        return (
            isSaving ||
            isLoading ||
            !selectedVariation ||
            !currentMoodBoardPlates.length
        );
    }, [isSaving, isLoading, selectedVariation, currentMoodBoardPlates]);

    const isCompareButtonDisabled =
        isSaveButtonDisabled ||
        variationHasNoPlates ||
        !!selectedVariation?.isTemplate;
    const isSavingOrIsLoading = isSaving || isLoading;

    // features

    const calculateBoxHeight = useCallback(() => {
        const topBarHeight =
            document.getElementById('room-view-header')?.clientHeight || 64;
        const bufferHeight = 4;
        setBoxHeight(window.innerHeight - topBarHeight - bufferHeight);
    }, []);

    const takePhoto = () => {
        moodboardRef.current?.takeScreenshot();
    };

    const onRoomCompare = () => {
        if (!designCodeProject && unsavedMoodboardChanges) {
            // use global modal first as this logic is also used elsewhere (breadcrumbs)
            // once this is dispatched the useEffect listens for it and
            // and local state is set accordingly for opening unsaved changes modal notification
            dispatch(
                setGlobalModalMoodboardUnsavedChanges({
                    open: true,
                    id: null,
                })
            );
            return;
        }

        // safeguard, button should be disabled anyhow
        // templates are not bound to a room so they don't have snapshots
        if (selectedVariation?.isTemplate) return;

        if (selectedVariation) {
            const compareWithVariationIdDispatched = dispatch(
                setCompareWithVariation(selectedVariation.id)
            );

            if (
                compareWithVariationIdDispatched.payload ===
                selectedVariation.id
            ) {
                navigate(appPath.comparison);
            }
        }
    };

    const navigateBack = () => {
        if (!designCodeProject && unsavedMoodboardChanges) {
            dispatch(
                setGlobalModalMoodboardUnsavedChanges({
                    open: true,
                    id: null,
                })
            );
            return;
        }

        navigate(-1);
    };

    const onSaveButtonClick = async () => {
        const isTemplate = selectedVariation?.isTemplate;

        // templates are not bound to a room so they don't have snapshots
        if (isTemplate) {
            if (!selectedVariation || !currentMoodBoardPlates.length) return;

            setIsSaving(true);

            const updateData = {
                data: JSON.stringify({
                    plates: currentMoodBoardPlates,
                }),
                products: productIds,
            };

            const savedTemplate = await updateVariation(
                selectedVariation.id,
                updateData
            );

            if (savedTemplate) {
                // update the selected variation and plates
                dispatch(setVariationSelected(savedTemplate));
                dispatch(updatePlates(currentMoodBoardPlates));

                // update local state with modified template
                setTemplatesFromDb(
                    (prevTemplates) =>
                        prevTemplates?.map((template) =>
                            template.id === savedTemplate.id
                                ? savedTemplate
                                : template
                        ) || []
                );
            }

            setIsSaving(false);

            // reset unsaved changes
            dispatch(setUnsavedChanges(false));

            // variations have snapshots and are bound to a room
        } else if (variationsWithoutSoftDelete?.length) {
            if (!selectedVariation || !currentMoodBoardPlates.length) {
                return;
            }

            // normally the isLoading from projectState is all that is needed
            // however atm. saving consists of multiple steps and uploading step
            // is not covered by store so using isSaving is more consistent atm.
            setIsSaving(true);

            let updatedVariation: Variation | null = null;

            // upload the screenshot to the server
            try {
                if (moodboardRef.current?.uploadScreenshot) {
                    // returns the updated variation with the new snapshot
                    updatedVariation =
                        await moodboardRef.current.uploadScreenshot();
                }
            } catch (error) {
                console.error('Error uploading screenshot:', error);
            } finally {
                // update variation with new plates also
                // & update store with both plates and uploaded snapshot
                if (updatedVariation?.id) {
                    // NB! the variation is already updated in server side
                    // with the new snapshot id (by uploadScreenshot())
                    const savedVariation = await dispatch(
                        updateVariationThunk({
                            id: selectedVariation.id,
                            updateData: {
                                data: JSON.stringify({
                                    plates: currentMoodBoardPlates,
                                }),
                                products: productIds,
                            },
                        })
                    ).unwrap(); // get the fulfilled result from dispatch with unwrap

                    // update the selected variation and plates
                    dispatch(setVariationSelected(savedVariation));
                    dispatch(updatePlates(currentMoodBoardPlates));
                }

                setIsSaving(false);

                // reset unsaved changes
                dispatch(setUnsavedChanges(false));
            }
        }
    };

    // refresh or token expiry
    const redirectIfNoId = useCallback(() => {
        if (authCookie) {
            if (!projects.length) {
                // offers empty - possibly refresh
                navigate(appPath.projects);
            }
        } else {
            // no auth cookie & no design code project
            if (!designCodeProject) {
                navigate(appPath.login);
            }
        }
    }, [authCookie, projects, navigate, designCodeProject]);

    const clearMoodboardPlates = useCallback(() => {
        dispatch(updatePlates([]));
    }, [dispatch]);

    // selects variation or template
    const selectVariation = useCallback(
        (variation: Variation) => {
            // if unsaved changes dont select new variation
            if (!designCodeProject && unsavedMoodboardChanges) {
                dispatch(
                    setGlobalModalMoodboardUnsavedChanges({
                        open: true,
                        id: null,
                    })
                );
                return;
            }

            dispatch(setVariationSelected(variation));
        },
        [dispatch, designCodeProject, unsavedMoodboardChanges]
    );

    const clearSelectedVariation = useCallback(() => {
        dispatch(clearVariationSelected());
    }, [dispatch]);

    const duplicateVariationFromCurrentPlates = useCallback(async () => {
        if (!roomId) {
            return;
        }

        await dispatch(
            createVariationThunk({
                name: generateHashFromTimestamp(),
                roomId: roomId,
                isTemplate: false,
                data: {
                    plates: currentMoodBoardPlates,
                } as Partial<MoodboardState>,
                products: productIds,
            })
        );
        // productIds is dependent on currentMoodBoardPlates
        // so no need to include it in the dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomId, dispatch, currentMoodBoardPlates]);

    const deleteVariation = useCallback(
        async (variationId: string) => {
            if (!roomId || !variationId) {
                return;
            }

            if (
                variationsWithoutSoftDelete &&
                variationsWithoutSoftDelete.length <= 1
            ) {
                setIsLastVariationModalOpen(true);
                return;
            }

            await dispatch(
                updateVariationThunk({
                    id: variationId,
                    updateData: { deleted: true },
                })
            );

            // If deleted variation was selected, clear it
            if (selectedVariation && selectedVariation.id === variationId) {
                clearSelectedVariation();
                clearMoodboardPlates();
            }
        },
        [
            roomId,
            dispatch,
            selectedVariation,
            clearSelectedVariation,
            clearMoodboardPlates,
            variationsWithoutSoftDelete,
        ]
    );

    // templates, although same as variations are not bound to a room nor a project
    // so no extra requests or populating redux store needed atm.
    const createTemplateFromCurrentPlates = useCallback(async () => {
        const createdTemplate = await createVariation(
            generateHashFromTimestamp(), // name
            true, // isTemplate
            { plates: currentMoodBoardPlates } as Partial<MoodboardState>, // data
            productIds // PIM product ids
        );

        setTemplatesFromDb((prevTemplates) => [
            ...(prevTemplates || []),
            createdTemplate,
        ]);
        // productIds is dependent on currentMoodBoardPlates
        // so no need to include it in the dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMoodBoardPlates]);

    const deleteTemplate = useCallback(async (id: string) => {
        if (!id) {
            return;
        }

        try {
            const updatedTemplate = await updateVariation(id, {
                deleted: true,
            });

            if (updatedTemplate) {
                // Update the local state to reflect the deleted template
                setTemplatesFromDb(
                    (prevTemplates) =>
                        prevTemplates?.filter(
                            (template) => template.id !== updatedTemplate.id
                        ) || []
                );
            }
        } catch (error) {
            console.error('Error deleting template:', error);
        }
    }, []);

    const renameTemplate = useCallback(
        async ({
            id,
            updateData,
        }: {
            id: string;
            updateData: Partial<Variation>;
        }) => {
            // variation id is coming from SpatialSwitcher.tsx
            // on variation/template item's menu icon click (openVariationOptionsMenu/openTemplateOptionsMenu)
            // and it's set by setVariationClickedOnSpatialSwitcher to be used in RenameVariation.tsx
            if (!id || !updateData) {
                return;
            }

            const updatedTemplate = await updateVariation(id, updateData);

            if (updatedTemplate) {
                // update local state as to not needing to refetch templates
                setTemplatesFromDb(
                    (prevTemplates) =>
                        prevTemplates?.map((template) =>
                            template.id === updatedTemplate.id
                                ? updatedTemplate
                                : template
                        ) || []
                );
            }
        },
        []
    );

    // create new variation if no variations in room
    // should trigger once after creating a room
    const createInitialVariation = useCallback(async () => {
        if (roomId && !variationsWithoutSoftDelete?.length) {
            await dispatch(
                createVariationThunk({
                    name: generateHashFromTimestamp(),
                    roomId: roomId,
                    isTemplate: false,
                    data: {
                        plates: [],
                    } as Partial<MoodboardState>,
                })
            );

            clearMoodboardPlates();
        }
    }, [dispatch, clearMoodboardPlates, roomId, variationsWithoutSoftDelete]);

    const getPlatesFromSelectedVariation = useCallback(async () => {
        if (!selectedVariation) {
            return;
        }

        const platesFromJson: Partial<MoodboardState> = JSON.parse(
            selectedVariation.data
        );

        if (platesFromJson.plates) {
            dispatch(updatePlates(platesFromJson.plates));
        }
    }, [selectedVariation, dispatch]);

    // reset moodboard unsaved changes to recent selected variation state
    const resetMoodboard = useCallback(() => {
        if (!designCodeProject && unsavedMoodboardChanges) {
            if (selectedVariation) {
                dispatch(setUnsavedChanges(false));
                getPlatesFromSelectedVariation();
            }
        }
    }, [
        designCodeProject,
        unsavedMoodboardChanges,
        selectedVariation,
        getPlatesFromSelectedVariation,
        dispatch,
    ]);

    // side effects

    // auth check
    useEffect(() => {
        redirectIfNoId();
    }, [redirectIfNoId]);

    // Clear moodboard before any dealings with variations & plates
    useEffect(() => {
        clearMoodboardPlates();
        clearSelectedVariation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set initial height and add resize event listener
    useEffect(() => {
        calculateBoxHeight();
        window.addEventListener('resize', calculateBoxHeight);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', calculateBoxHeight);
        };
    }, [calculateBoxHeight]);

    useEffect(() => {
        if (shouldNavigateAway()) {
            return;
        }

        (async () => {
            await createInitialVariation();
        })();
    }, [createInitialVariation, shouldNavigateAway]);

    // Auto-select any variation available
    useEffect(() => {
        if (shouldNavigateAway() || !variationsWithoutSoftDelete?.length) {
            return;
        }

        if (!selectedVariation) {
            const firstVariation = variationsWithoutSoftDelete[0];
            selectVariation(firstVariation);
        }
    }, [
        shouldNavigateAway,
        variationsWithoutSoftDelete,
        selectedVariation,
        selectVariation,
    ]);

    // Load new plates on to moodboard when variation/template changes
    useEffect(() => {
        if (shouldNavigateAway()) {
            return;
        }

        if (selectedVariation) {
            getPlatesFromSelectedVariation();
        }
    }, [selectedVariation, getPlatesFromSelectedVariation, shouldNavigateAway]);

    // fetch templates & filter out soft deleted ones
    useEffect(() => {
        // important for design code session when refreshing page
        if (shouldNavigateAway()) {
            return;
        }

        (async () => {
            // if design code project, no need to fetch templates
            if (designCodeProject) {
                return;
            }

            const response = await getTemplatesByLimit(20).catch((error) => {
                console.error('Error fetching templates in UI:', error);
                return null;
            });

            const templates = response?.docs?.filter(
                (template: Variation) => !template.deleted
            );

            if (templates?.length) {
                setTemplatesFromDb(templates);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // open unsaved changes dialog
    useEffect(() => {
        if (globalModals.moodboard.unsavedChanges.open) {
            // open dialog
            setIsUnsavedChangesModalOpen(true);
        } else {
            // close dialog
            setIsUnsavedChangesModalOpen(false);
        }
    }, [globalModals]);

    return (
        <Box component={'div'}>
            <Stack
                ref={stackRef}
                sx={styles.roomViewHeader}
                id="room-view-header"
            >
                <BreadcrumbNavigation />
                <Stack direction={'row'} alignItems={'center'} gap={2}>
                    <IconButton
                        onClick={takePhoto}
                        disabled={isSaveButtonDisabled}
                        sx={getIconButtonStyle(isSaveButtonDisabled)}
                    >
                        <PhotoCameraOutlined />
                        <Typography sx={styles.headerButtonText}>
                            {t(`views.configurator.screenshot`)}
                        </Typography>
                    </IconButton>
                    <IconButton
                        onClick={onSaveButtonClick}
                        disabled={isSaveButtonDisabled || !!designCodeProject}
                        sx={getIconButtonStyle(isSaveButtonDisabled)}
                    >
                        <SaveOutlined />
                        <Typography sx={styles.headerButtonText}>
                            {t(`save`)}
                        </Typography>
                    </IconButton>

                    <IconButton
                        onClick={resetMoodboard}
                        disabled={!unsavedMoodboardChanges}
                        sx={getIconButtonStyle(!unsavedMoodboardChanges)}
                    >
                        <RestoreOutlined />
                        <Typography sx={styles.headerButtonText}>
                            {t(`views.configurator.resetToSavedChanges`)}
                        </Typography>
                    </IconButton>

                    <IconButton
                        onClick={onRoomCompare}
                        disabled={isCompareButtonDisabled}
                        sx={getIconButtonStyle(isCompareButtonDisabled)}
                    >
                        <CompareOutlined />
                        <Typography sx={styles.headerButtonText}>
                            {t(`compare`)}
                        </Typography>
                    </IconButton>

                    <IconButton
                        onClick={navigateBack}
                        disabled={isSavingOrIsLoading}
                        sx={getIconButtonStyle(isSavingOrIsLoading)}
                    >
                        <ExitToAppOutlined />
                        <Typography sx={styles.headerButtonText}>
                            {t(`back`)}
                        </Typography>
                    </IconButton>
                </Stack>
            </Stack>
            <Divider />
            {variationsWithoutSoftDelete && (
                <Box component={'div'}>
                    {/* Dynamic size for box */}
                    <Box component={'div'} height={`${boxHeight}px`}>
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            {/* Moodboard canvas */}
                            <Moodboard
                                ref={moodboardRef}
                                isDisabled={isSavingOrIsLoading}
                            />
                        </ErrorBoundary>

                        {/* Animated left side panel */}
                        {!designCodeProject && (
                            <ConfiguratorPanel
                                isClosed={isclosedConfiguratorPanel}
                                setPanelClose={(value: boolean) => {
                                    setIsClosedConfiguratorPanel(value);
                                }}
                            >
                                {/* PIM product picker */}

                                <MaterialPicker
                                    isClosed={isclosedConfiguratorPanel}
                                    setPanelClose={(value: boolean) => {
                                        setIsClosedConfiguratorPanel(value);
                                    }}
                                />
                            </ConfiguratorPanel>
                        )}
                    </Box>

                    {/* Variations and templates picker */}
                    <SpatialSwitcher
                        isSaving={isSavingOrIsLoading}
                        // Variations
                        variations={
                            variationsWithoutSoftDelete?.sort(
                                (a, b) =>
                                    new Date(a.createdAt ?? 0).getTime() -
                                    new Date(b.createdAt ?? 0).getTime()
                            ) || []
                        }
                        selectedVariation={selectedVariation} // *also for templates
                        onSelectingNewVariation={selectVariation} // *also for templates
                        onDuplicateVariation={
                            // *also for templates
                            // (creates a variation from canvas state even if it's a template)
                            duplicateVariationFromCurrentPlates
                        }
                        onDeleteVariation={deleteVariation}
                        onOpenRenameVariationDialog={() => {
                            // *also for templates
                            // both variations and templates are of type Variation
                            // and have isTemplate info
                            setIsRenameVariationDialogOpen(true);
                        }}
                        setVariationClickedOnSpatialSwitcher={
                            // *also for templates
                            // sets the selected variation/template for renaming/deleting
                            // on clicking on the list item menu icon (3 dots)
                            setVariationClickedOnSpatialSwitcher
                        }
                        // Templates
                        templates={
                            templatesFromDb?.sort(
                                (a, b) =>
                                    new Date(b.createdAt ?? 0).getTime() -
                                    new Date(a.createdAt ?? 0).getTime()
                            ) || []
                        }
                        onCreateNewTemplate={createTemplateFromCurrentPlates}
                        onDeleteTemplate={deleteTemplate}
                    />
                </Box>
            )}

            {/* modals */}

            {/* last variation delete notification */}
            <NotificationModal
                isOpen={isLastVariationModalOpen}
                onClose={() => {
                    setIsLastVariationModalOpen(false);
                }}
                alertText={t(`component.moodboardNotification.lastVariation`)}
            />
            {/* unsaved changes notification */}
            <NotificationModal
                isOpen={isUnsavedChangesModalOpen}
                onClose={() => {
                    dispatch(
                        setGlobalModalMoodboardUnsavedChanges({
                            open: false,
                            id: null,
                        } as GlobalModalData)
                    );
                }}
                alertText={t(`component.moodboardNotification.unsavedChanges`)}
            />
            {/* For both variations and templates */}
            <RenameVariation
                variation={variationClickedOnSpatialSwitcher}
                isRenameVariationDialogOpen={isRenameVariationDialogOpen}
                onCloseRenameVariationDialog={() => {
                    setVariationClickedOnSpatialSwitcher(null);
                    setIsRenameVariationDialogOpen(false);
                }}
                onRenameTemplate={renameTemplate}
            />
        </Box>
    );
};

export default RoomView;

const styles = {
    roomViewHeader: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginRight: '1rem',
        minHeight: '4rem',
    },
    headerButtonText: {
        marginLeft: '0.5rem',
        fontSize: '1rem',
        color: colors.black,
    },
};

const getIconButtonStyle = (isDisabled: boolean) => ({
    opacity: isDisabled ? 0.2 : 1,
    color: colors.black,
    '&:hover': {
        backgroundColor: 'transparent',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
    },
    pointerEvents: isDisabled ? 'none' : 'auto',
});

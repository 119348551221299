import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { Box, Typography, FormControl, Select, MenuItem } from '@mui/material';

// ui
import { colors } from 'theming/colors';
import { PLACEHOLDER_IMAGE_URL } from 'config';

// store
import { selectDesignCodeProject } from 'features/projects/state/projectState';

// services
import { PIMService } from 'services/PIMService';

// types
import { ComparisonViewVariationSnapshot } from './ComparisonView';

interface SnapshotSelectProps {
    spacialSolutions: SpacialSolution[];
    onSnapshotClick: (snapshot: ComparisonViewVariationSnapshot) => void;
}

const SnapshotSelect: React.FC<SnapshotSelectProps> = (
    props: SnapshotSelectProps
) => {
    const { t } = useTranslation();

    // local state

    const [selectedSolution, setSelectedSolution] =
        useState<SpacialSolution | null>(null);

    const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);

    // for design code or user session snapshot urls
    const [processedUrls, setProcessedUrls] = useState<Record<string, string>>(
        {}
    );

    // properties

    const { spacialSolutions } = props;

    const designCodeProject = useSelector(selectDesignCodeProject);

    // latest snapshot for each room
    const latestSnapshots: ComparisonViewVariationSnapshot[] =
        React.useMemo(() => {
            if (!spacialSolutions?.length) return [];

            const filteredSolutions = selectedSolution
                ? spacialSolutions.filter(
                      (solution) => solution.id === selectedSolution.id
                  )
                : spacialSolutions;

            return filteredSolutions.flatMap(
                (solution: SpacialSolution) =>
                    solution.rooms?.flatMap((room: Room) => {
                        if (
                            !room?.variations?.length ||
                            !room.variations[0]?.snapshots?.length
                        )
                            return [];

                        const firstVariationLatestSnapshot =
                            room.variations[0].snapshots[
                                room.variations[0].snapshots.length - 1
                            ];
                        const snapshot = firstVariationLatestSnapshot?.url
                            ? [
                                  {
                                      snapshotURL:
                                          firstVariationLatestSnapshot?.url,
                                      thumbnailURL:
                                          firstVariationLatestSnapshot?.sizes
                                              ?.thumbnail?.url ||
                                          firstVariationLatestSnapshot?.sizes
                                              ?.medium?.url ||
                                          firstVariationLatestSnapshot?.url,
                                      variation: room.variations[0],
                                      room: room,
                                  },
                              ]
                            : [];

                        return snapshot;
                    }) || []
            );
        }, [spacialSolutions, selectedSolution]);

    const everyVariationLatestSnapshot: ComparisonViewVariationSnapshot[] =
        React.useMemo(() => {
            if (!selectedRoom) return [];

            const filteredSnapshots =
                selectedRoom.variations?.flatMap((variation: Variation) => {
                    if (!variation?.snapshots?.length) return [];

                    const latestSnapshot: Snapshot =
                        variation.snapshots[variation.snapshots.length - 1];

                    return latestSnapshot
                        ? [
                              {
                                  snapshotURL: latestSnapshot.url,
                                  thumbnailURL:
                                      latestSnapshot.sizes?.thumbnail?.url ||
                                      latestSnapshot.sizes?.medium?.url ||
                                      latestSnapshot.url,
                                  variation,
                                  room: selectedRoom,
                              },
                          ]
                        : [];
                }) || [];

            return filteredSnapshots;
        }, [selectedRoom]);

    // features

    const handleSolutionChange = (event: any) => {
        const selectedId = event.target.value as string;
        const solution =
            spacialSolutions.find((sol) => sol.id === selectedId) || null;
        setSelectedRoom(null);
        setSelectedSolution(solution);
    };

    const onRoomSnapshotClick = (snapshot: ComparisonViewVariationSnapshot) => {
        if (snapshot?.room?.id) {
            setSelectedRoom(snapshot.room);
        }
    };

    const onVariationSnapshotClick = (
        snapshot: ComparisonViewVariationSnapshot | null
    ) => {
        if (!snapshot) {
            return;
        }
        props.onSnapshotClick(snapshot);
    };

    // process image urls depending on design code or user session
    const processImage = useCallback(
        async (imageUrl: string) => {
            const pimService = new PIMService();
            if (!imageUrl) {
                return '';
            }

            if (designCodeProject) {
                const designCodeParameter = `?designcode=${designCodeProject.designCode}`;
                return imageUrl + designCodeParameter;
            } else {
                const image = await pimService.getImage(imageUrl);
                if (image) {
                    return URL.createObjectURL(image);
                }
            }
            return imageUrl;
        },
        [designCodeProject]
    );

    // side effects

    useEffect(() => {
        const processUrls = async () => {
            const newProcessedUrls: Record<string, string> = {};

            // Process each room's first variation's latest snapshot
            for (const snapshot of latestSnapshots) {
                if (
                    snapshot.thumbnailURL &&
                    !processedUrls[snapshot.thumbnailURL]
                ) {
                    newProcessedUrls[snapshot.thumbnailURL] =
                        await processImage(snapshot.thumbnailURL);
                }
            }

            // Process each variation's latest snapshot
            for (const snapshot of everyVariationLatestSnapshot) {
                if (
                    snapshot.thumbnailURL &&
                    !processedUrls[snapshot.thumbnailURL]
                ) {
                    newProcessedUrls[snapshot.thumbnailURL] =
                        await processImage(snapshot.thumbnailURL);
                }
            }

            setProcessedUrls((prev) => ({ ...prev, ...newProcessedUrls }));
        };

        processUrls();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestSnapshots, everyVariationLatestSnapshot, processImage]);

    return (
        <React.Fragment>
            <Box component="div" sx={style.container}>
                {/* Spacial solution heading */}
                <Box component="div" sx={style.heading}>
                    <Typography sx={style.textBold}>
                        {t('views.comparison.solution')}
                    </Typography>
                </Box>

                {/* Solution Selector */}
                <FormControl
                    variant="outlined"
                    fullWidth
                    sx={style.solutionSelection}
                >
                    <Select
                        labelId="solution-select-label"
                        id="solution-select"
                        value={selectedSolution ? selectedSolution.id : ''}
                        onChange={handleSolutionChange}
                        size="small"
                        sx={{
                            minHeight: '30px',
                            paddingY: '4px',
                            '& .MuiSelect-select': {
                                paddingY: '4px',
                                display: 'flex',
                                alignItems: 'center',
                            },
                            '& fieldset': {
                                borderColor: colors.borderGrey,
                            },
                        }}
                    >
                        {spacialSolutions.map((solution) => (
                            <MenuItem
                                key={solution.id}
                                value={solution.id}
                                sx={{
                                    paddingY: '4px',
                                    fontSize: '0.875rem',
                                }}
                            >
                                {solution.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {selectedSolution && latestSnapshots.length > 0 && (
                    // Rooms heading
                    <Box
                        component="div"
                        sx={{ ...style.heading, marginBottom: '0.4rem' }}
                    >
                        <Typography sx={style.textBold}>
                            {t('views.comparison.rooms')}
                        </Typography>
                    </Box>
                )}
                {/* Each room's first variation's latest snapshot */}
                <Box component="div" sx={style.snapshotsSelection}>
                    {latestSnapshots.length === 0 && (
                        <Typography variant="body1" sx={style.noSnapshots}>
                            {t('views.comparison.noSnapshots')}
                        </Typography>
                    )}
                    {selectedSolution &&
                        latestSnapshots.map(
                            (
                                snapshot: ComparisonViewVariationSnapshot,
                                index: number
                            ) => (
                                <Box
                                    component="div"
                                    key={index}
                                    sx={{
                                        ...style.snapshotItem,
                                        backgroundImage: `url(${
                                            processedUrls[
                                                snapshot.thumbnailURL
                                            ] || snapshot.thumbnailURL
                                        })`,
                                        position: 'relative',
                                        border:
                                            snapshot.room.id ===
                                            selectedRoom?.id
                                                ? `2px solid ${colors.orange}`
                                                : 'none',
                                        transform:
                                            snapshot.room.id ===
                                            selectedRoom?.id
                                                ? 'scale(1.05)'
                                                : 'scale(1)',
                                    }}
                                    onClick={() => {
                                        onRoomSnapshotClick(snapshot);
                                    }}
                                >
                                    <Box
                                        component="div"
                                        sx={style.snapshotItemOverlay}
                                    >
                                        {snapshot?.room?.name}
                                    </Box>
                                </Box>
                            )
                        )}
                </Box>
            </Box>

            {/* Variation snapshot selection */}
            {selectedRoom?.variations && (
                <Box component="div" sx={style.container}>
                    {/* Variations heading */}
                    <Box component="div" sx={style.heading}>
                        <Typography sx={style.textBold}>
                            {t('views.comparison.variations')}
                        </Typography>
                    </Box>

                    {/* Variations selection */}
                    <Box component="div" sx={style.roomSelection}>
                        {selectedRoom?.variations?.map((variation) => {
                            const currentVariationLatestSnapshot =
                                everyVariationLatestSnapshot.find(
                                    (snapshot) =>
                                        snapshot.variation.id === variation.id
                                ) || null;

                            if (!currentVariationLatestSnapshot) {
                                return (
                                    <Box
                                        component="div"
                                        key={variation.id}
                                        sx={{
                                            ...style.snapshotItem,
                                            backgroundImage: `url(${PLACEHOLDER_IMAGE_URL})`,
                                            position: 'relative',
                                        }}
                                        onClick={() => {
                                            onVariationSnapshotClick(null);
                                        }}
                                    >
                                        <Box
                                            component="div"
                                            sx={style.snapshotItemOverlay}
                                        >
                                            {variation.name}
                                        </Box>
                                    </Box>
                                    // <Typography variant="body1">
                                    //     {t(
                                    //         'views.comparison.noVariationSnapshot'
                                    //     )}
                                    // </Typography>
                                );
                            }

                            return (
                                <Box
                                    component="div"
                                    key={variation.id}
                                    sx={{
                                        ...style.snapshotItem,
                                        backgroundImage: `url(${
                                            processedUrls[
                                                currentVariationLatestSnapshot
                                                    .thumbnailURL
                                            ] ||
                                            currentVariationLatestSnapshot.thumbnailURL
                                        })`,
                                        position: 'relative',
                                    }}
                                    onClick={() => {
                                        onVariationSnapshotClick(
                                            currentVariationLatestSnapshot
                                        );
                                    }}
                                >
                                    <Box
                                        component="div"
                                        sx={style.snapshotItemOverlay}
                                    >
                                        {variation.name}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            )}
        </React.Fragment>
    );
};

export default SnapshotSelect;

const style = {
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',

        width: '27rem',
        // maxHeight: '18rem',
        padding: '1rem 1rem 1rem 1rem',
        backgroundColor: colors.white,
    },
    heading: {
        alignSelf: 'flex-start',
    },
    solutionSelection: {
        marginY: '1rem',
    },
    snapshotsSelection: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: 2,

        width: '100%',
        maxHeight: '8rem',
        // overflow: 'hidden',
        overflowY: 'scroll',
        padding: '0.4rem',
    },
    roomSelection: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 2,

        width: '100%',
        maxHeight: '8rem',
        overflowY: 'scroll',
        padding: '0.4rem',
    },
    snapshotItem: {
        width: '120px',
        height: '120px',

        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '8px',
        boxShadow: 3,
        flexShrink: 0,
        cursor: 'pointer',
        transition: 'transform 0.2s',
        '&:hover': {
            transform: 'scale(1.05)',
        },
        position: 'relative',
    },
    snapshotItemOverlay: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        textAlign: 'center',
        padding: '4px 0',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
    },
    noSnapshots: {
        margin: '1rem',
    },
    textPrimary: {
        color: '#000',
    },
    textBold: {
        fontWeight: 'bold',
    },
};

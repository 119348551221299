import { useTranslation } from 'react-i18next';

import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export interface RowOptionsMenuProps extends MenuProps {
    itemID: string;
    onClose: () => void;
    setIsDeleteSolutionDialogOpen: () => void;
    setIsModifySolutionDialogOpen: () => void;
}

const RowOptionsMenu = (props: RowOptionsMenuProps) => {
    const { t } = useTranslation();

    // properties

    // strip extra arguments from props to please the 'Menu' component
    let {
        setIsDeleteSolutionDialogOpen: _,
        setIsModifySolutionDialogOpen: __,
        ...strippedProps
    } = props;

    // features

    const onChangeDetailsClick = () => {
        props.onClose();
        props.setIsModifySolutionDialogOpen();
    };

    const onDeleteClick = () => {
        props.onClose();
        props.setIsDeleteSolutionDialogOpen();
    };

    return (
        <Menu {...strippedProps}>
            <MenuItem onClick={onChangeDetailsClick}>
                {t(`component.roomOptionsMenu.modify`)}
            </MenuItem>
            <MenuItem onClick={onDeleteClick}>{t(`delete`)}</MenuItem>
        </Menu>
    );
};

export default RowOptionsMenu;

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PLACEHOLDER_IMAGE_URL } from 'config';

// mui
import { Box, Grid, Typography, CircularProgress } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

// ui
import TooltipStyled from 'components/TooltipStyled';
import { colors } from 'theming/colors';

// store
import { selectDesignCodeProject } from 'features/projects/state/projectState';
import { selectCurrentLanguage } from 'features/localization/state/localizationState';

// services
import { PIMService } from 'services/PIMService';

// types
import { ProductFeatureName } from './ProductListView';

interface ProductItemOverviewProps {
    product: PIMProduct;
    translate: (key: string) => string;
    index: number;
    array: PIMProduct[];
}

const ProductItemOverview = ({
    product,
    translate,
    index,
    array,
}: ProductItemOverviewProps) => {
    // local state

    const [processedImageUrl, setProcessedImageUrl] = useState('');
    const [loading, setLoading] = useState<boolean>(true);

    // properties

    const selectedLocale = useSelector(selectCurrentLanguage);
    const designCodeProject = useSelector(selectDesignCodeProject);
    const baseUrl = window.location.origin;

    // go through all image groups and get the first one that exists
    const productImageUrl =
        product?.designImageSlider?.[0]?.designImage?.sizes?.thumbnail?.url ||
        product?.designImageSlider?.[0]?.designImage?.sizes?.medium?.url ||
        product?.designImageSlider?.[0]?.designImage?.url ||
        product?.interiorImageSlider?.[0]?.interiorImage?.sizes?.thumbnail
            ?.url ||
        product?.interiorImageSlider?.[0]?.interiorImage?.sizes?.medium?.url ||
        product?.interiorImageSlider?.[0]?.interiorImage?.url ||
        product?.referenceAlbumSlider?.[0]?.referenceAlbums?.sizes?.thumbnail
            ?.url ||
        product?.referenceAlbumSlider?.[0]?.referenceAlbums?.sizes?.medium
            ?.url ||
        product?.referenceAlbumSlider?.[0]?.referenceAlbums?.url ||
        '';

    // Product details

    const createdAt = product?.createdAt
        ? new Date(product.createdAt).toLocaleString(selectedLocale)
        : '';

    const productName = product?.name;
    const productProductCode = product?.externalId;
    const productCollection = product?.category?.value?.name;
    const productSubcategory = product?.subGroup?.value?.name;
    const productDimensions = product?.featuresArray?.find((feature) =>
        feature?.featureName
            ?.toLowerCase()
            ?.includes(ProductFeatureName.PLATE_FORMAT)
    )?.featureValue;
    const productThickness = product?.featuresArray?.find((feature) =>
        feature?.featureName
            ?.toLowerCase()
            ?.includes(ProductFeatureName.THICKNESS)
    )?.featureValue;
    const productManufacturer = product?.category?.value?.factory?.value?.name;
    const productPrice =
        product?.price &&
        Number.parseFloat(product.price.toString()).toFixed(2);

    // Is product off the production/sale

    const isProductOffFromActiveProduction =
        product.inActiveProduction !== undefined && !product.inActiveProduction;
    const isProductOffFromActiveSale =
        product.inActiveSale !== undefined && !product.inActiveSale;

    // features

    const processImage = useCallback(async () => {
        setLoading(true);
        const pimService = new PIMService();
        if (!productImageUrl) {
            setLoading(false);
            return;
        }

        if (designCodeProject) {
            const designCodeParameter = `?designcode=${designCodeProject.designCode}`;
            await pimService
                .getImage(productImageUrl + designCodeParameter)
                .then((image) => {
                    if (image) {
                        setProcessedImageUrl(URL.createObjectURL(image));
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setProcessedImageUrl(`${baseUrl}${PLACEHOLDER_IMAGE_URL}`);
                    setLoading(false);
                });
        } else {
            await pimService
                .getImage(productImageUrl)
                .then((image) => {
                    if (image) {
                        setProcessedImageUrl(URL.createObjectURL(image));
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setProcessedImageUrl(`${baseUrl}${PLACEHOLDER_IMAGE_URL}`);
                    setLoading(false);
                });
        }
    }, [productImageUrl, designCodeProject, baseUrl]);

    // side effects

    useEffect(() => {
        processImage();
    }, [product, processImage]);

    return (
        <React.Fragment key={product.id}>
            <Grid
                item
                xs={3}
                sx={{ ...styles.gridItem, ...getBorder(index, array) }}
                data-product-block // for PDF export
            >
                {/* PIM Product name with image */}
                <Box component="div" sx={styles.gridFirstItem}>
                    <Box component="div" sx={styles.imageBox}>
                        {loading ? (
                            <Box component="div" sx={styles.circularLoaderBox}>
                                <CircularProgress size={20} />
                            </Box>
                        ) : (
                            <img
                                src={
                                    processedImageUrl ||
                                    `${baseUrl}${PLACEHOLDER_IMAGE_URL}`
                                }
                                alt={'thumbnail'}
                                onError={(
                                    e: React.SyntheticEvent<
                                        HTMLImageElement,
                                        Event
                                    >
                                ) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = `${baseUrl}${PLACEHOLDER_IMAGE_URL}`;
                                }}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '0',
                                    objectFit: 'contain',
                                }}
                            />
                        )}
                    </Box>

                    <Box component="div" sx={styles.gridFirstItemTextBox}>
                        <Typography
                            sx={{
                                ...styles.gridFirstItemText,
                                ...styles.textBold,
                            }}
                        >
                            {productName}
                        </Typography>
                        {(isProductOffFromActiveSale ||
                            isProductOffFromActiveProduction) && (
                            <Box component="div" sx={{ color: colors.orange }}>
                                {
                                    <TooltipStyled
                                        title={
                                            isProductOffFromActiveSale
                                                ? translate(
                                                      'views.productList.notInActiveSales'
                                                  )
                                                : isProductOffFromActiveProduction
                                                ? translate(
                                                      'views.productList.notInActiveProduction'
                                                  )
                                                : ''
                                        }
                                        placement="top-start"
                                    >
                                        <WarningAmberOutlinedIcon />
                                    </TooltipStyled>
                                }
                            </Box>
                        )}
                    </Box>
                </Box>
            </Grid>

            <Grid
                item
                xs={2}
                sx={{ ...styles.gridItem, ...getBorder(index, array) }}
            >
                {/* Product name & product code */}
                <Box component="div" sx={styles.gridDataBox}>
                    <Box component="div" sx={styles.gridDataItem}>
                        <Typography
                            sx={{
                                ...styles.gridDataItemTitle,
                            }}
                        >
                            {translate('views.productList.productCreatedAt')}
                        </Typography>

                        <Typography
                            sx={{
                                ...styles.gridDataItemValue,
                            }}
                        >
                            {createdAt}
                        </Typography>
                    </Box>

                    <Box component="div" sx={styles.gridDataItem}>
                        <Typography
                            sx={{
                                ...styles.gridDataItemTitle,
                            }}
                        >
                            {translate('views.productList.productCode')}
                        </Typography>

                        <Typography
                            sx={{
                                ...styles.gridDataItemValue,
                            }}
                        >
                            {productProductCode}
                        </Typography>
                    </Box>
                </Box>
            </Grid>

            <Grid
                item
                xs={2}
                sx={{ ...styles.gridItem, ...getBorder(index, array) }}
            >
                {/* Product collection (PIM category) & sub-category (PIM subgroup) */}
                <Box component="div" sx={styles.gridDataBox}>
                    <Box component="div" sx={styles.gridDataItem}>
                        <Typography
                            sx={{
                                ...styles.gridDataItemTitle,
                            }}
                        >
                            {translate('views.productList.collection')}
                        </Typography>

                        <Typography
                            sx={{
                                ...styles.gridDataItemValue,
                            }}
                        >
                            {productCollection}
                        </Typography>
                    </Box>

                    <Box component="div" sx={styles.gridDataItem}>
                        <Typography
                            sx={{
                                ...styles.gridDataItemTitle,
                            }}
                        >
                            {translate('views.productList.subcategory')}
                        </Typography>

                        <Typography
                            sx={{
                                ...styles.gridDataItemValue,
                            }}
                        >
                            {productSubcategory}
                        </Typography>
                    </Box>
                </Box>
            </Grid>

            <Grid
                item
                xs={2}
                sx={{ ...styles.gridItem, ...getBorder(index, array) }}
            >
                {/* Format & Thickness */}
                <Box component="div" sx={styles.gridDataBox}>
                    {productDimensions && (
                        <Box component="div" sx={styles.gridDataItem}>
                            <Typography
                                sx={{
                                    ...styles.gridDataItemTitle,
                                }}
                            >
                                {translate('views.productList.dimensionFormat')}
                            </Typography>

                            <Typography
                                sx={{
                                    ...styles.gridDataItemValue,
                                }}
                            >
                                {productDimensions} {'(cm)'}
                            </Typography>
                        </Box>
                    )}

                    {productThickness && (
                        <Box component="div" sx={styles.gridDataItem}>
                            <Typography
                                sx={{
                                    ...styles.gridDataItemTitle,
                                }}
                            >
                                {translate('views.productList.thickness')}
                            </Typography>

                            <Typography
                                sx={{
                                    ...styles.gridDataItemValue,
                                }}
                            >
                                {productThickness}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Grid>

            <Grid
                item
                xs={1}
                sx={{ ...styles.gridItem, ...getBorder(index, array) }}
            >
                {/* Manufacturer (PIM factory) & price */}
                <Box component="div" sx={styles.gridDataBox}>
                    {productManufacturer && (
                        <Box component="div" sx={styles.gridDataItem}>
                            <Typography
                                sx={{
                                    ...styles.gridDataItemTitle,
                                }}
                            >
                                {translate('views.productList.manufacturer')}
                            </Typography>

                            <Typography
                                sx={{
                                    ...styles.gridDataItemValue,
                                }}
                            >
                                {productManufacturer}
                            </Typography>
                        </Box>
                    )}

                    {productPrice && (
                        <Box component="div" sx={styles.gridDataItem}>
                            <Typography
                                sx={{
                                    ...styles.gridDataItemTitle,
                                }}
                            >
                                {translate('views.productList.price')}
                            </Typography>

                            <Typography
                                sx={{
                                    ...styles.gridDataItemValue,
                                }}
                            >
                                {productPrice} {'(€/m2)'}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Grid>
        </React.Fragment>
    );
};

export default ProductItemOverview;

const styles = {
    // -- Grid first item elements --
    gridItem: {
        boxSizing: 'border-box',
        marginBottom: '0.5rem',
        paddingBottom: '0.5rem',
    },
    gridFirstItem: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',

        padding: '0.5rem',
    },
    gridFirstItemTextBox: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden',
    },
    gridFirstItemText: {
        fontSize: '1rem',
        wordBreak: 'break-word',
    },

    // Grid item elements
    gridDataBox: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',

        padding: '0.5rem 0.5rem 0 0.5rem',
    },
    gridDataItem: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',

        paddingBottom: '0.5rem',
    },
    gridDataItemTitle: {
        fontSize: '0.8rem',
        color: colors.fGrey,
    },
    gridDataItemValue: {
        fontSize: '0.8rem',
    },

    // -- Shared --
    imageBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        width: '80px',
        height: '80px',
        marginRight: '0.5rem',
        flexShrink: 0,
    },
    textBold: {
        fontWeight: '600',
    },
    circularLoaderBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
};

const getBorder = (index: number, array: PIMProduct[]) => {
    return {
        borderBottom:
            index < array.length - 1
                ? '1px solid ' + colors.borderGrey
                : '1px solid ' + colors.borderGrey,
    };
};

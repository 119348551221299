import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

// ui
import Button from 'components/base/Button';

// store
import {
    updateVariationThunk,
    selectIsLoading,
} from 'features/projects/state/projectState';

// types
import { AppDispatch } from 'store';

export interface RenameVariationProps {
    variation: Variation | null; // variation or template
    isRenameVariationDialogOpen: boolean;
    onCloseRenameVariationDialog: () => void;
    onRenameTemplate: ({
        id,
        updateData,
    }: {
        id: string;
        updateData: Partial<Variation>;
    }) => void;
}

const RenameVariation = (props: RenameVariationProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();

    // local state

    const [variationName, setVariationName] = useState('');

    // properties

    const isLoadingProjectState = useSelector(selectIsLoading);
    const selectedVariation = props.variation;
    const isTemplate = props.variation?.isTemplate;

    // features

    const handleRenameVariation = async () => {
        if (!selectedVariation) return;

        if (isTemplate) {
            const updateData: Partial<Variation> = { name: variationName };
            props.onRenameTemplate({ id: selectedVariation.id, updateData });
            props.onCloseRenameVariationDialog();
        } else {
            const updateData: Partial<Variation> = { name: variationName };
            await dispatch(
                updateVariationThunk({ id: selectedVariation.id, updateData })
            ).catch((error) => {
                console.error('Error renaming variation in UI:', error);
            });
            props.onCloseRenameVariationDialog();
        }
    };

    // side effects

    useEffect(() => {
        if (selectedVariation) {
            setVariationName(selectedVariation.name);
        }

        return () => {
            setVariationName('');
        };
    }, [selectedVariation, props.isRenameVariationDialogOpen]);

    return (
        <Dialog
            open={props.isRenameVariationDialogOpen}
            onClose={props.onCloseRenameVariationDialog}
        >
            {/* Right top close button */}
            <IconButton
                aria-label="close"
                onClick={props.onCloseRenameVariationDialog}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.main,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Title */}
            <Box component="div" sx={styles.titleBox}>
                <DialogTitle>
                    {isTemplate
                        ? t(`component.renameVariationDialog.titleTemplate`)
                        : t(`component.renameVariationDialog.titleVariation`)}
                </DialogTitle>
            </Box>

            {/* Content */}
            <DialogContent>
                <TextField
                    variant="standard"
                    placeholder={t(
                        `component.renameVariationDialog.placeholder`
                    )}
                    fullWidth
                    rows={4}
                    sx={{ marginTop: '2rem' }}
                    onChange={(event) => {
                        setVariationName(event.target.value);
                    }}
                    value={variationName}
                ></TextField>
            </DialogContent>

            {/* Actions */}
            <DialogActions sx={styles.dialogActions}>
                <Button
                    sx={styles.buttonCancel}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={props.onCloseRenameVariationDialog}
                >
                    {t(`cancel`)}
                </Button>
                <Button
                    sx={styles.buttonSubmit}
                    variant={'contained'}
                    type="submit"
                    onClick={handleRenameVariation}
                    disabled={variationName === ''}
                >
                    {isLoadingProjectState ? (
                        <CircularProgress size={20} />
                    ) : (
                        t(`submit`)
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RenameVariation;

const styles = {
    titleBox: {
        marginRight: '2rem',
    },
    dialogActions: {
        justifyContent: 'flex-end',
        gap: '0.4rem',
        margin: '0px 0.5rem 0.5rem 0px',
    },
    buttonCancel: {
        height: '2.8rem',
    },
    buttonSubmit: {
        height: '2.8rem',
    },
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// r3f
import { useFrame, useLoader, ThreeEvent } from '@react-three/fiber';
import { Html, Outlines, TransformControls } from '@react-three/drei';
import * as THREE from 'three';
import { DragControls } from 'three/examples/jsm/controls/DragControls';

// config
import { colors } from 'theming/colors';
import { pivotConfigs } from './configs';

// mui
import { Stack } from '@mui/system';
import { Box, CircularProgress, Portal } from '@mui/material';

// ui
import ToolButtons from './PlateTools';

// services
import { PIMService } from 'services/PIMService';

// store
import { AppDispatch } from 'store';
import { setUnsavedChanges, updatePlateById } from './moodboardState';
import { selectDesignCodeProject } from 'features/projects/state/projectState';

// types
import { PlateInterface, PlateType, TextureData, ToolType } from './types';

const Plate: React.FC<PlateInterface> = ({
    plateData,
    focused,
    onPress,
    onDelete,
    platePanelContainer,
    index,
    movePlateUp,
    movePlateDown,
}) => {
    // design code session if set
    const designCodeProject = useSelector(selectDesignCodeProject);

    const transformRef = useRef(null);
    const meshRef = useRef<THREE.Mesh>(null);
    const groupRef = useRef<THREE.Group>(null);
    const dragControlsRef = useRef<DragControls | null>(null);
    const [selectedTool, setSelectedTool] = useState<ToolType>('translate');
    const [isRotating, setIsRotating] = useState(false);
    const [targetRotation, setTargetRotation] = useState(0);
    const [locked, setLocked] = useState(plateData.locked);
    const dispatch: AppDispatch = useDispatch();
    const initialPosition = plateData.position;
    const initialRotation = plateData.rotation;
    const [height, setHeight] = useState(plateData.height);
    const [currentImgIndex, setCurrentImgIndex] = useState(0);
    const pimService = new PIMService();
    const [textureURL, setTextureURL] = useState('/placeholder_plate.png');
    const texture = useLoader(THREE.TextureLoader, textureURL);
    const [loadingTexture, setLoadingTexture] = useState(false);
    const i18n = useTranslation();
    const boxRef = useRef<THREE.BoxGeometry>(null);
    const [geometryReady, setGeometryReady] = useState(false);
    const [geometryDimensions, setGeometryDimensions] = useState([1, 1, 0.1]);
    const [currentTransformScale, setCurrentTransformScale] = useState<THREE.Vector3 | null>(null);

    const plateThickness = 1;

    const handleObjectChange = useCallback((data: {
        newLock?: boolean;
        newRotation?: number;
        newHeight?: number;
        newIndex?: number;
        cropData?: {
            scale: { x: number; y: number };
            offset: number[];
        };
        transformScale?: [number, number, number];
    }) => {
        if (!transformRef.current || !meshRef.current) return;

        let updatedTextures: TextureData[] = [];
        const posX = (transformRef.current as any).worldPosition.x;
        const posY = (transformRef.current as any).worldPosition.y;
        const posZ = (transformRef.current as any).worldPosition.z;
        const rotX = meshRef.current.rotation.x;
        const rotY = meshRef.current.rotation.y;
        const scaleX = data.transformScale ? data.transformScale[0] : meshRef.current?.scale.x;
        const scaleY = data.transformScale ? data.transformScale[1] : meshRef.current?.scale.y;
        const scaleZ = data.transformScale ? data.transformScale[2] : plateThickness; // constant thickness
        const newHeight = data.newHeight || plateData.height;
        const newRotation = data.newRotation || plateData.rotation[2];
        const newLock = data.newLock || plateData.locked;

        const id = plateData.id;

        if (data.newIndex !== undefined) {
            updatedTextures = plateData.textures.map((texture, index) => ({
                ...texture,
                isSelected: index === data.newIndex,
            }));
        }

        const updatedPlate = {
            ...plateData,
            position: [posX, posY, posZ],
            transformScale: [scaleX, scaleY, scaleZ],
            height: newHeight, // layering height
            rotation: [rotX, rotY, newRotation],
            locked: newLock,
            ...(updatedTextures.length && { textures: updatedTextures }),
            ...(data.cropData && { cropData: data.cropData })
        };

        dispatch(updatePlateById({ id, updatedPlate }));
        dispatch(setUnsavedChanges(true));
    }, [plateData, dispatch]);

    const handleNextImage = useCallback(() => {
        setCurrentImgIndex((prevIndex) => {
            const newIndex = prevIndex === plateData.textures.length - 1 ? 0 : prevIndex + 1;
            handleObjectChange({ newIndex });
            return newIndex;
        });
    }, [plateData.textures.length, handleObjectChange]);

    const handlePreviousImage = useCallback(() => {
        setCurrentImgIndex((prevIndex) => {
            const newIndex = prevIndex === 0 ? plateData.textures.length - 1 : prevIndex - 1;
            handleObjectChange({ newIndex });
            return newIndex;
        });
    }, [plateData.textures.length, handleObjectChange]);

    const handlePlateClick = (event: ThreeEvent<MouseEvent>) => {
        if (designCodeProject) return;

        // Stop event propagation to prevent clicking through to lower plates
        event.stopPropagation();

        if (selectedTool === 'inactive') setSelectedTool('translate');
        onPress && onPress();
        if (locked) setSelectedTool('inactive');
    };

    // resetTransformations() removed

    const lock = () => {
        setLocked(!locked);
        !locked ? setSelectedTool('inactive') : setSelectedTool('translate');
        handleObjectChange({ newLock: !locked });
    };

    const deletePlate = () => {
        if (onDelete) onDelete();
    };

    const transformScale = plateData.transformScale;
    const [initialSpawning, setInitialSpawning] = useState(true);

    const mode = () => {
        switch (selectedTool) {
            case 'translate':
                return 'translate';
            case 'rotate':
                return 'rotate';
            case 'scale':
                return 'scale';
            case 'crop':
                return 'scale';
            default:
                return 'translate';
        }
    };

    const fetchTexture = async () => {
        setLoadingTexture(true);
        if (plateData.textures.length === 0) {
            setLoadingTexture(false);
            alert(i18n.t('moodboard.noMaterialsFound'));
            if (onDelete) onDelete();
            return;
        }

        // find the texture that has been previously selected or get the first one
        const selectedTexture =
            plateData.textures.find((texture, index) => {
                if (texture.isSelected) {
                    setCurrentImgIndex(index);
                    return texture;
                }
                return false;
            }) || plateData.textures[0];

        let fetchedTexture: Blob | null = null;
        const imageUrl = selectedTexture.src;

        if (designCodeProject) {
            const designCodeParameter = `?designcode=${designCodeProject.designCode}`;
            fetchedTexture = await pimService.getImage(
                imageUrl + designCodeParameter
            );
        } else {
            fetchedTexture = await pimService.getImage(imageUrl);
        }

        if (fetchedTexture !== null) {
            const objectURL = URL.createObjectURL(fetchedTexture);
            setTextureURL(objectURL);

            // get dimensions
            const img = new Image();
            img.src = objectURL;
            img.onload = () => {
                const aspectRatio = img.width / img.height;
                const width = 1;
                const height = 1 / aspectRatio;
                setGeometryDimensions([width, height, 0.01]);
            };
        } else {
            alert(i18n.t('moodboard.noMaterialsFound'));
            if (onDelete) onDelete();
        }

        setLoadingTexture(false);

        if (texture) {
            const textureLoader = new THREE.TextureLoader();
            textureLoader.load(
                textureURL,
                (loadedTexture) => {
                    // disable texture repeating
                    loadedTexture.wrapS = loadedTexture.wrapT = THREE.ClampToEdgeWrapping;
                    loadedTexture.repeat.set(1, 1);
                    loadedTexture.needsUpdate = true;

                    // set white background color for areas beyond texture
                    if (meshRef.current) {
                        const material = meshRef.current.material as THREE.MeshStandardMaterial;
                        if (material) {
                            material.color.setHex(0xffffff);
                        }
                    }
                },
                undefined,
                (error) => {
                    console.error('error loading texture:', error);
                }
            );
        }
    };

    // fetch texture and geometry state on img change
    useEffect(() => {
        const loadTexture = async () => {
            setGeometryReady(false); // reset
            await fetchTexture();

            // wait for next frame to ensure geometry is updated
            requestAnimationFrame(() => {
                setGeometryReady(true);
            });
        };

        loadTexture();

        // cleanup
        return () => {
            if (textureURL.startsWith('blob:')) {
                URL.revokeObjectURL(textureURL);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentImgIndex]);

    useEffect(() => {
        if (dragControlsRef.current) {
            (dragControlsRef.current as any).enabled = focused; // Enable or disable DragControls based on focus
        }
    }, [focused]);

    useFrame(() => {
        if (initialSpawning) {
            meshRef.current?.scale.set(
                transformScale[0],
                transformScale[1],
                transformScale[2]
            );
            setInitialSpawning(false);
        }

        if (selectedTool === 'scale' && meshRef.current) {
            const scale = meshRef.current.scale;
            const uniformScale = scale.x;
            // preserve z scale for layering (movePlateUp/Down) to work
            meshRef.current.scale.set(uniformScale, uniformScale, plateThickness);
        }
    });

    useFrame(() => {
        if (isRotating && meshRef.current) {
            const currentRotation = meshRef.current.rotation.z;
            meshRef.current.rotation.z = THREE.MathUtils.lerp(
                currentRotation,
                targetRotation,
                0.1
            );
            if (Math.abs(currentRotation - targetRotation) < 0.01) {
                setIsRotating(false);
            }
        }
    });

    const handleRotateClick = () => {
        if (meshRef.current) {
            const currentRotation = meshRef.current.rotation.z;
            const newRotation = currentRotation + -Math.PI / 2;
            const roundedRotation =
                Math.round(newRotation / (Math.PI / 2)) * (Math.PI / 2);
            setTargetRotation(roundedRotation);
            handleObjectChange({ newRotation: roundedRotation });
            setIsRotating(true);
        }
    };

    const openPlate = async () => {
        const plateInfo: PlateType = plateData;

        if (plateInfo?.pimProductId) {
            const product = await pimService.getProductById(
                plateInfo.pimProductId,
                0
            );

            if (product) {
                // const url = product.overviewUrl; // broken atm 07.01.2025
                // need to replace newUrl with product.overviewUrl as soon as it's fixed
                const newUrl = `https://datasheet.floorin.ee/?collection=flProducts&itemId=${product.id}&hideImg=true&locale=et`;
                window.open(newUrl, '_blank', 'noreferrer');
            }
        }
    };

    useEffect(() => {
        const x = initialRotation[0];
        const y = initialRotation[1];
        const z = initialRotation[2];

        if (transformRef.current) {
            (transformRef.current as any).position.y = height;
        }

        if (meshRef.current) {
            (meshRef.current as any).rotation.set(x, y, z);
            (meshRef.current as any).position.y = height;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleMoveDown = () => {
        // force translate tool when starting to change layer order
        setSelectedTool('translate');
        movePlateDown();
    };

    const handleMoveUp = () => {
        setSelectedTool('translate');
        movePlateUp();
    };

    const onToolClick = (tool: ToolType) => {
        switch (tool) {
            case 'translate':
                setSelectedTool('translate');
                break;
            case 'rotate':
                handleRotateClick();
                break;
            case 'moveDown':
                handleMoveDown();
                break;
            case 'moveUp':
                handleMoveUp();
                break;
            case 'scale':
                setSelectedTool('scale');
                break;
            case 'crop':
                setSelectedTool('crop');
                break;
            case 'lock':
                lock();
                break;
            case 'delete':
                deletePlate();
                break;
            case 'info':
                openPlate();
                break;
            case 'previous':
                handlePreviousImage();
                break;
            case 'next':
                handleNextImage();
                break;
        }
    };

    useFrame(() => {
        setHeight(plateData.height);
        if (transformRef.current) {
            (transformRef.current as any).position.y = plateData.height;
        }
        if (meshRef.current) {
            (meshRef.current as any).position.y = plateData.height;
        }
    });

    const handleTransformChange = useCallback(
        (e?: THREE.Event) => {
            if (selectedTool === 'crop' && meshRef.current && texture) {
                const worldScale = new THREE.Vector3();
                meshRef.current.getWorldScale(worldScale);

                // get original geometry dimensions
                const originalWidth = geometryDimensions[0];
                const originalHeight = geometryDimensions[1];

                // get current scale
                const currentWidth = worldScale.x;
                const currentHeight = worldScale.y;

                // maintain aspect ratio for geometry scaling
                const uniformScale = Math.min(currentWidth, currentHeight);
                meshRef.current.scale.set(uniformScale, uniformScale, plateThickness);

                // calculate scale factors
                const scaleFactorX = originalWidth / currentWidth;
                const scaleFactorY = originalHeight / currentHeight;

                // use the larger scale factor to ensure texture fills the geometry
                const scaleFactor = Math.max(scaleFactorX, scaleFactorY);

                // set texture repeat to maintain aspect ratio and prevent stretching
                texture.repeat.set(1 / scaleFactor, 1 / scaleFactor);
                texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;

                // calculate offset to keep texture centered
                const offsetX = (1 - 1 / scaleFactor) / 2;
                const offsetY = (1 - 1 / scaleFactor) / 2;
                texture.offset.set(offsetX, offsetY);

                // update material settings
                const material = meshRef.current.material as THREE.MeshStandardMaterial;
                if (material) {
                    material.color.setHex(0xffffff);
                }
            }
        },
        [selectedTool, texture, geometryDimensions]
    );

    const handleTransformEnd = useCallback(() => {
        if (selectedTool === 'crop' && meshRef.current && texture) {
            const worldScale = new THREE.Vector3();
            meshRef.current.getWorldScale(worldScale);

            // Save the final crop state
            handleObjectChange({
                cropData: {
                    scale: {
                        x: texture.repeat.x,
                        y: texture.repeat.y,
                    },
                    offset: texture.offset.toArray(),
                },
                transformScale: [
                    meshRef.current.scale.x,
                    meshRef.current.scale.y,
                    plateThickness,
                ],
            });
        } else {
            // update plate data for other tools
            handleObjectChange({});
        }
    }, [selectedTool, texture, handleObjectChange]);

    // set texture crop state from plate data after transform & on initial texture loading
    useEffect(() => {
        if (texture && plateData.cropData && meshRef.current) {
            // Restore texture state
            texture.repeat.set(
                plateData.cropData.scale.x,
                plateData.cropData.scale.y
            );
            texture.offset.set(
                plateData.cropData.offset[0],
                plateData.cropData.offset[1]
            );

            // Restore mesh scale
            if (plateData.transformScale) {
                meshRef.current.scale.set(
                    plateData.transformScale[0],
                    plateData.transformScale[1],
                    plateThickness
                );
            }
        }
    }, [texture, plateData.cropData, plateData.transformScale]);

    return (
        <>
            <TransformControls
                onMouseUp={handleTransformEnd}
                position={
                    selectedTool === 'crop' || selectedTool === 'scale'
                        ? undefined
                        : [
                              initialPosition[0],
                              initialPosition[1],
                              initialPosition[2],
                          ]
                }
                ref={transformRef}
                enabled={focused}
                mode={mode()}
                showX={focused && pivotConfigs[selectedTool].activeAxes[0]}
                showY={focused && pivotConfigs[selectedTool].activeAxes[1]}
                showZ={focused && pivotConfigs[selectedTool].activeAxes[2]}
                object={
                    selectedTool === 'crop' || selectedTool === 'scale'
                        ? meshRef.current || undefined
                        : undefined
                }
                onChange={handleTransformChange}
            >
                <group
                    ref={groupRef}
                    castShadow
                    receiveShadow
                    onClick={(e) => {
                        e.stopPropagation();
                        handlePlateClick(e);
                    }}
                >
                    <mesh
                        ref={meshRef}
                        castShadow
                        receiveShadow
                        renderOrder={plateData.height * 100}
                        onClick={(e) => {
                            e.stopPropagation();
                            handlePlateClick(e);
                        }}
                    >
                        {!loadingTexture && (
                            <boxGeometry
                                args={[
                                    geometryDimensions[0],
                                    geometryDimensions[1],
                                    geometryDimensions[2],
                                ]}
                                ref={boxRef}
                            />
                        )}
                        <meshStandardMaterial map={texture} />
                        {focused && boxRef.current && geometryReady && (
                            <Outlines
                                thickness={0.01}
                                color={colors.orange}
                                screenspace
                            />
                        )}
                    </mesh>

                    {/* {loadingError && (
                    <Html zIndexRange={[1, 2]} center>
                        <Image sx={{ transform: 'scale(2)' }} color="warning" />
                    </Html>
                )} */}

                    {loadingTexture && (
                        <Html zIndexRange={[1, 2]} center>
                            <CircularProgress />
                        </Html>
                    )}
                </group>
            </TransformControls>
            <Html>
                <Portal container={() => platePanelContainer?.current}>
                    {!loadingTexture && focused && !designCodeProject && (
                        <Box id={'plateBox'} component="div">
                            <Stack
                                direction="column"
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                                bgcolor={'white'}
                                padding={2}
                                borderRadius={3}
                                sx={{
                                    boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Stack direction="row" spacing={1}>
                                    <ToolButtons
                                        selectedTool={selectedTool}
                                        locked={locked}
                                        currentImageIndex={currentImgIndex + 1}
                                        totalImages={plateData.textures.length}
                                        onToolClick={onToolClick}
                                    />
                                </Stack>
                                {/* <Slider /> element  removed, see GH ref: pull/50 */}
                            </Stack>
                        </Box>
                    )}
                </Portal>
            </Html>
        </>
    );
};

export default Plate;

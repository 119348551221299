import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { colors } from 'theming/colors';
import LazyLoad from 'react-lazy-load';
import { PLACEHOLDER_IMAGE_URL } from 'config';
import { useTranslation } from 'react-i18next';

// mui
import { Typography, Box, CircularProgress } from '@mui/material';

// store
import { selectDesignCodeProject } from 'features/projects/state/projectState';

// services
import { PIMService } from 'services/PIMService';

// types
import { ProductFeatureName } from 'features/productList/ProductListView';
export interface ProductImageProps {
    product: PIMProduct;
    onProductClick: (product: PIMProduct) => void;
}

function ProductImage(props: ProductImageProps) {
    const { t } = useTranslation();

    // local state

    const [imageLoading, setImageLoading] = useState(true);
    const [fetchedProductImageUrl, setFetchedProductImageUrl] = useState('');

    // properties

    const designCodeProject = useSelector(selectDesignCodeProject);

    const { product, onProductClick } = props;
    const baseUrl = window.location.origin;
    const productFormat = product?.featuresArray?.find(
        (item: PIMProductFeature) =>
            item?.featureName
                ?.toLowerCase()
                ?.includes(ProductFeatureName.PLATE_FORMAT)
    );
    const plateThickness = product?.featuresArray?.find(
        (item: PIMProductFeature) =>
            item?.featureName?.toLowerCase() ===
            ProductFeatureName.THICKNESS.toLowerCase()
    );

    // Collection name in UI
    const productCategory = product?.category?.value?.name;

    // go through all image groups and get the first one that exists
    const productImageUrl =
        product?.designImageSlider?.[0]?.designImage?.sizes?.thumbnail?.url ||
        product?.designImageSlider?.[0]?.designImage?.sizes?.medium?.url ||
        product?.designImageSlider?.[0]?.designImage?.url ||
        product?.interiorImageSlider?.[0]?.interiorImage?.sizes?.thumbnail
            ?.url ||
        product?.interiorImageSlider?.[0]?.interiorImage?.sizes?.medium?.url ||
        product?.interiorImageSlider?.[0]?.interiorImage?.url ||
        product?.referenceAlbumSlider?.[0]?.referenceAlbums?.sizes?.thumbnail
            ?.url ||
        product?.referenceAlbumSlider?.[0]?.referenceAlbums?.sizes?.medium
            ?.url ||
        product?.referenceAlbumSlider?.[0]?.referenceAlbums?.url ||
        '';

    // features

    const processImage = useCallback(async () => {
        const pimService = new PIMService();
        if (!productImageUrl) {
            return;
        }

        if (designCodeProject) {
            const designCodeParameter = `?designcode=${designCodeProject.designCode}`;
            setFetchedProductImageUrl(productImageUrl + designCodeParameter);
        } else {
            await pimService.getImage(productImageUrl).then((image) => {
                if (image) {
                    setFetchedProductImageUrl(URL.createObjectURL(image));
                }
            });
        }
    }, [productImageUrl, designCodeProject]);

    // side effects

    useEffect(() => {
        processImage();
    }, [product, processImage]);

    return (
        <Box
            key={product.id}
            component={'div'}
            sx={style.productItemContainer}
            onClick={() => onProductClick(product)}
        >
            <Box component={'div'} sx={style.productItem}>
                <Box component={'div'} sx={style.productImage}>
                    {imageLoading && (
                        <Box component={'div'} sx={style.loadingContainer}>
                            <CircularProgress size={24} />
                        </Box>
                    )}
                    <LazyLoad>
                        <img
                            src={fetchedProductImageUrl}
                            alt={product.name}
                            onLoad={() => setImageLoading(false)}
                            onError={(e) => {
                                setImageLoading(false);
                                e.currentTarget.onerror = null;
                                e.currentTarget.src = `${baseUrl}${PLACEHOLDER_IMAGE_URL}`;
                            }}
                            style={{ display: imageLoading ? 'none' : 'block' }}
                        />
                    </LazyLoad>
                </Box>
                <Box component={'div'} sx={style.productInfo}>
                    {/* Product name */}
                    {product?.name && (
                        <Typography sx={style.productName}>
                            {product.name}
                        </Typography>
                    )}

                    {/* Product collection */}
                    {productCategory && (
                        <>
                            <Typography sx={style.productSubtitle}>
                                {t('materialPicker.collection')}:&nbsp;
                            </Typography>
                            <Typography sx={style.productSubtitleValue}>
                                {productCategory}
                            </Typography>
                        </>
                    )}

                    {/* Product format */}
                    {productFormat?.featureValue && (
                        <>
                            <Typography sx={style.productSubtitle}>
                                {productFormat?.featureName}:&nbsp;
                            </Typography>
                            <Typography sx={style.productSubtitleValue}>
                                {productFormat?.featureValue}
                            </Typography>
                            <br />
                        </>
                    )}

                    {/* Product thickness */}
                    {plateThickness?.featureValue && (
                        <>
                            <Typography sx={style.productSubtitle}>
                                {plateThickness?.featureName}:&nbsp;
                            </Typography>
                            <Typography sx={style.productSubtitleValue}>
                                {plateThickness?.featureValue}&nbsp;
                            </Typography>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

// Memoize the component with custom comparison to improve performance
export default React.memo(
    (props: ProductImageProps) => {
        return (
            <ProductImage
                product={props.product}
                onProductClick={props.onProductClick}
            />
        );
    },
    (prevProps, nextProps) => {
        return prevProps.product.id === nextProps.product.id;
    }
);

const style = {
    productItemContainer: {
        display: 'inline-flex',
        justifyContent: 'center',

        border: '0px solid black',
        boxSizing: 'border-box',
        padding: '0rem',
        margin: '0.1rem 0.2rem 0.1rem 0.2rem',
        height: 'auto',
        width: 'auto',
        cursor: 'pointer',
        transition: 'transform 0.1s ease-in-out',

        '&:hover': {
            transform: 'scale(1.0)',
            boxShadow: '0 4px 16px 1px rgba(0, 0, 0, 0.1)',
        },
    },
    productItem: {
        display: 'flex',
        flexDirection: 'column',

        boxSizing: 'border-box',
        border: '0px solid black',
        padding: '0',
        margin: '0',
        minHeight: '26rem',
        width: '9rem',

        overflow: 'hidden',
        wordWrap: 'break-word',
    },
    productImage: {
        flex: 1,
        position: 'relative',

        '& img': {
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
        },
    },
    loadingContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        boxSizing: 'border-box',
    },
    productInfo: {
        flex: 2,
        padding: '0.2rem',
        boxSizing: 'border-box',
    },
    productName: {
        fontSize: '0.8rem',
        boxSizing: 'border-box',
        marginBottom: '0.4rem',
        borderBottom: `1px solid ${colors.lGrey}`,
        height: '4rem',
    },
    productSubtitle: {
        display: 'inline-block',
        fontSize: '0.8rem',
        fontWeight: 'bold',
        color: colors.fGrey,
        boxSizing: 'border-box',
    },
    productSubtitleValue: {
        display: 'inline-block',
        fontSize: '0.8rem',
        color: colors.fGrey,
        boxSizing: 'border-box',
    },
};

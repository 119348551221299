import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

// mui
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';

// ui
import Button from 'components/base/Button';

// store
import {
    createProject,
    duplicateProject,
    selectIsLoading,
    selectProjects,
} from 'features/projects/state/projectState';
import { AppDispatch } from 'store';

// services
import { OdooService } from 'services/OdooService';

// types

type LeadOption = {
    key: string;
    id: string | number;
    name: string;
    partnerName: string;
};

interface NewProjectDialogProps {
    isDuplicate: boolean; // should VO be duplicated or created as a new
    open: boolean;
    onClose: () => void;
    projectId?: string;
}

const NewProjectDialog = (props: NewProjectDialogProps) => {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const theme = useTheme();

    // local state

    const [odooLeadName, setOdooLeadName] = useState('');
    const [odooPartnerName, setOdooPartnerName] = useState('');
    const [projectComment, setProjectComment] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<LeadOption[]>([]);
    const [autocompleteListOpen, setAutocompleteListOpen] = useState(false);
    const [autocompleteLead, setAutocompleteLead] = useState<LeadOption | null>(
        null
    );
    const [isLoadingOdooLeads, setIsLoadingOdooLeads] = useState(false);

    // properties

    const isLoadingProjectState = useSelector(selectIsLoading);

    const projects = useSelector(selectProjects);
    const currentVisualOffering = projects.docs.find(
        (project) => project.id === props.projectId
    );

    // features

    const resetAllFields = () => {
        // Reset all state fields to their initial values
        setOdooLeadName('');
        setOdooPartnerName('');
        setProjectComment('');
        setSearchTerm('');
        setSearchResults([]);
        setAutocompleteListOpen(false);
        setAutocompleteLead(null);
    };

    const requestLeadsByName = useCallback(async (name: string) => {
        const odooService = new OdooService();
        setIsLoadingOdooLeads(true);
        try {
            const response = await odooService.getLeadsByName(name);
            const formattedResults: LeadOption[] = response.map((item) => ({
                key: `${item.id}-${item.name}`, // Name & or id's could repeat
                id: item.id,
                name: item.name,
                partnerName: item.partner_name,
            }));
            setSearchResults(formattedResults);
            setAutocompleteListOpen(true);
        } catch (error) {
            console.error('Error fetching leads:', error);
            setSearchResults([]);
            setAutocompleteListOpen(false);
        } finally {
            setIsLoadingOdooLeads(false);
        }
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((term: string) => {
            if (term.length > 0) {
                requestLeadsByName(term);
            } else {
                setSearchResults([]);
                setAutocompleteListOpen(false);
            }
        }, 600),
        [requestLeadsByName]
    );

    const handleSearchChange = (
        event: React.ChangeEvent<{}>,
        value: string,
        reason: string
    ) => {
        setSearchTerm(value);
        if (reason === 'input') {
            debouncedSearch(value);
        }
    };

    const handleLeadSelect = (
        event: React.ChangeEvent<{}>,
        value: LeadOption | null
    ) => {
        setAutocompleteLead(value);
        if (value) {
            setOdooLeadName(value.name);
            setOdooPartnerName(value.partnerName);
        } else {
            setOdooLeadName('');
            setOdooPartnerName('');
        }
    };

    const handleClose = () => {
        resetAllFields();
        props.onClose();
    };

    const handleSubmit = async () => {
        if (!autocompleteLead) return;

        // Create new offer
        if (!props.isDuplicate) {
            await dispatch(
                createProject({
                    data: {
                        name: odooLeadName,
                        odooLead: {
                            leadId: Number(autocompleteLead.id),
                            leadCustomerName: odooPartnerName || '',
                        },
                    } as VisualOfferingSaveData,
                    comment: projectComment,
                })
            ).catch((error) => {
                console.error('Error creating Visual Offering in UI:', error);
            });
        }
        // Duplicate offer
        else {
            if (!props.projectId) return;

            await dispatch(
                duplicateProject({
                    data: {
                        visualOfferingId: props.projectId,
                        odooLeadId: Number(autocompleteLead.id),
                        odooLeadName: odooLeadName,
                        odooLeadCustomerName: odooPartnerName || '',
                    } as VisualOfferingDuplicationData,
                })
            ).catch((error) => {
                console.error(
                    'Error duplicating Visual Offering in UI:',
                    error
                );
            });
        }

        handleClose();
    };

    // side effects

    useEffect(() => {
        // Cleanup on unmount
        return () => {
            debouncedSearch.cancel();
            resetAllFields();
        };
    }, [debouncedSearch]);

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth="sm" fullWidth>
            {/* Right top close button */}
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.secondary.main,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Title */}
            <DialogTitle>
                {props.isDuplicate
                    ? t(`component.newProjectDialog.duplicateTitle`)
                    : t(`component.newProjectDialog.title`)}
            </DialogTitle>

            {/* Content */}
            <DialogContent>
                {/* Lead name from Odoo */}
                <Box component="div" sx={{ width: '100%', mt: 2 }}>
                    <FormControl fullWidth variant="standard">
                        <Autocomplete
                            open={autocompleteListOpen}
                            onOpen={() => setAutocompleteListOpen(true)}
                            onClose={() => setAutocompleteListOpen(false)}
                            options={searchResults ?? []}
                            noOptionsText={t(
                                `component.newProjectDialog.noResults`
                            )}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t(
                                        `component.newProjectDialog.findOdooClient`
                                    )}
                                    variant="standard"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <>
                                                {isLoadingOdooLeads ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={28}
                                                    />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            inputValue={searchTerm}
                            onInputChange={handleSearchChange}
                            onChange={handleLeadSelect}
                            value={autocompleteLead}
                            isOptionEqualToValue={(option, value) =>
                                String(option.id) === String(value.id)
                            }
                            filterOptions={(x) => x ?? []}
                            getOptionKey={(option) => option.key}
                        />
                    </FormControl>
                </Box>

                {/* Partner name */}
                <TextField
                    disabled={true}
                    variant="standard"
                    label={t(`component.modifyProjectDialog.client`)}
                    fullWidth
                    rows={4}
                    sx={{ marginTop: '2rem' }}
                    onChange={(event) => {
                        setOdooPartnerName(event.target.value);
                    }}
                    value={odooPartnerName ?? ''}
                ></TextField>

                {/* Visual offer name if duplicating offer */}
                {props.isDuplicate && (
                    <TextField
                        disabled={true}
                        variant="standard"
                        label={t(`component.newProjectDialog.visualOfferName`)}
                        fullWidth
                        rows={4}
                        sx={{ marginTop: '2rem' }}
                        value={currentVisualOffering?.name ?? ''}
                    ></TextField>
                )}

                {/* Comment if new offer */}
                {!props.isDuplicate && (
                    <TextField
                        variant="standard"
                        placeholder={t(`component.newProjectDialog.comment`)}
                        fullWidth
                        multiline
                        helperText="0/100"
                        rows={4}
                        sx={{ marginTop: '2rem' }}
                        onChange={(event) => {
                            setProjectComment(event.target.value);
                        }}
                    ></TextField>
                )}
            </DialogContent>

            {/* Actions */}
            <DialogActions sx={styles.dialogActions}>
                <Button
                    sx={styles.buttonCancel}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={handleClose}
                >
                    {t(`cancel`)}
                </Button>
                <Button
                    sx={styles.buttonSubmit}
                    variant={'contained'}
                    type="submit"
                    onClick={handleSubmit}
                    disabled={odooPartnerName === '' || isLoadingProjectState}
                >
                    {isLoadingProjectState ? (
                        <CircularProgress size={20} />
                    ) : (
                        t(`submit`)
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewProjectDialog;

const styles = {
    dialogActions: {
        justifyContent: 'flex-end',
        gap: '0.4rem',
        margin: '0px 0.5rem 0.5rem 0px',
    },
    buttonCancel: {
        height: '2.8rem',
    },
    buttonSubmit: {
        height: '2.8rem',
    },
};

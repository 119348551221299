import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'theming/colors';
import { getElementDistanceFromTop } from 'utils';
import { useSelector } from 'react-redux';

// mui
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';

import ImagesearchRollerIcon from '@mui/icons-material/ImagesearchRoller';
import GridViewIcon from '@mui/icons-material/GridView';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';

// ui
import ItemOptions from './ItemOptions';
import ItemOptionsMenu from './ItemOptionsMenu';
import Button from 'components/base/Button';

// store
import { selectDesignCodeProject } from 'features/projects/state/projectState';

export interface SpatialSwitcherProps {
    // variations
    variations: Variation[];
    selectedVariation: Variation | null;
    onSelectingNewVariation: (variation: Variation) => void;
    onDuplicateVariation: () => void;
    onDeleteVariation: (variationId: string) => void;
    onOpenRenameVariationDialog: () => void;
    setVariationClickedOnSpatialSwitcher: (variation: Variation) => void;
    // templates
    templates: Variation[] | null;
    onCreateNewTemplate: () => void;
    onDeleteTemplate: (templateId: string) => void;
    isSaving: boolean;
}

const SpatialSwitcher = (props: SpatialSwitcherProps) => {
    const canvasRef = useRef<HTMLElement | null>(null);
    const { t } = useTranslation();

    // local state

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [menuVariation, setMenuVariation] = useState<Variation | null>(null);
    const [menuTemplate, setMenuTemplate] = useState<Variation | null>(null);

    const [variationOpen, setVariationOpen] = useState(false);
    const [templateOpen, setTemplateOpen] = useState(false);

    const [heightUntilCanvas, setHeightUntilCanvas] = useState<number | null>(
        null
    );

    // properties

    // if set it's a design code session (store is populated with designCodeProject)
    const designCodeProject = useSelector(selectDesignCodeProject);

    const { isSaving } = props;

    const variationList =
        props.variations?.map((variation: Variation) => {
            return (
                <ListItemButton
                    key={variation.id}
                    disabled={isSaving}
                    style={{
                        color:
                            props.selectedVariation?.id === variation.id
                                ? `${colors.orange}`
                                : 'inherit',
                    }}
                    onClick={() => {
                        props.onSelectingNewVariation(variation);
                    }}
                >
                    {variation.name}
                    <ItemOptions
                        onClick={(
                            event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                            event.stopPropagation();
                            openVariationOptionsMenu(event, variation);
                        }}
                        disabled={!!designCodeProject}
                    />
                </ListItemButton>
            );
        }) || [];

    // template is same as variation but without associated room or project
    // so there's an extra request to get templates (variations with isTemplate flag true)
    const templateList =
        props.templates?.map((template: Variation) => {
            return (
                <ListItemButton
                    key={template.id}
                    disabled={isSaving}
                    style={{
                        color:
                            props.selectedVariation?.id === template.id
                                ? `${colors.orange}`
                                : 'inherit',
                    }}
                    onClick={() => {
                        props.onSelectingNewVariation(template);
                    }}
                >
                    {template.name}
                    <ItemOptions
                        onClick={(
                            event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                            event.stopPropagation();
                            openTemplateOptionsMenu(event, template);
                        }}
                    />
                </ListItemButton>
            );
        }) || [];

    // features

    const calculateHeightUntilCanvas = () => {
        if (canvasRef.current) {
            const distance = getElementDistanceFromTop(canvasRef);
            setHeightUntilCanvas(distance);
        }
    };

    const openVariationMenu = () => {
        setTemplateOpen(false);
        setVariationOpen(!variationOpen);
    };

    const openTemplateMenu = () => {
        setVariationOpen(false);
        setTemplateOpen(!templateOpen);
    };

    const onDuplicateVariation = () => {
        props.onDuplicateVariation();
    };

    const onCreateNewTemplate = () => {
        props.onCreateNewTemplate();
    };

    const resetMenuState = () => {
        setMenuAnchorEl(null);
        setMenuVariation(null);
        setMenuTemplate(null);
    };

    const onItemsMenuClose = () => {
        resetMenuState();
    };

    const openVariationOptionsMenu = (
        event: React.MouseEvent<HTMLButtonElement>,
        variation: Variation
    ) => {
        setMenuAnchorEl(event.currentTarget);
        setMenuVariation(variation);
        props.setVariationClickedOnSpatialSwitcher(variation);
    };

    const openTemplateOptionsMenu = (
        event: React.MouseEvent<HTMLButtonElement>,
        template: Variation
    ) => {
        setMenuAnchorEl(event.currentTarget);
        setMenuTemplate(template);

        // template data type is same as variation
        // only need to pass on the data & check the isTemplate flag
        props.setVariationClickedOnSpatialSwitcher(template);
        // props.setTemplateClickedOnSpatialSwitcher(template);
    };

    // side effects

    // for menu element positioning
    useEffect(() => {
        calculateHeightUntilCanvas();
        window.addEventListener('resize', () => {
            calculateHeightUntilCanvas();
        });

        return () => {
            window.removeEventListener('resize', () => {
                calculateHeightUntilCanvas();
            });
        };
    }, [props]);

    // for menu element positioning
    useEffect(() => {
        canvasRef.current = document.getElementsByTagName('canvas')[0];
    }, []);

    return (
        <>
            <Stack
                sx={{
                    ...style.parent,
                    top: heightUntilCanvas
                        ? `${heightUntilCanvas + 32}px`
                        : '12rem',
                }}
                direction="column"
                spacing={0}
            >
                <Paper sx={style.paper}>
                    <Stack direction="row" spacing={2}>
                        <Button
                            color={variationOpen ? 'primary' : 'secondary'}
                            variant="text"
                            sx={style.tabButton}
                            startIcon={<ImagesearchRollerIcon />}
                            onClick={openVariationMenu}
                            endIcon={
                                variationOpen ? (
                                    <ArrowDropUp />
                                ) : (
                                    <ArrowDropDown />
                                )
                            }
                            disabled={isSaving}
                        >
                            {t(`views.configurator.variations`)}
                        </Button>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />

                        <Button
                            color={templateOpen ? 'primary' : 'secondary'}
                            variant="text"
                            sx={style.tabButton}
                            startIcon={<GridViewIcon />}
                            onClick={openTemplateMenu}
                            endIcon={
                                templateOpen ? (
                                    <ArrowDropUp />
                                ) : (
                                    <ArrowDropDown />
                                )
                            }
                            disabled={isSaving || !!designCodeProject}
                        >
                            {t(`views.configurator.templates`)}
                        </Button>
                    </Stack>
                </Paper>
                {variationOpen || templateOpen ? (
                    <Paper sx={{ ...style.paper, marginTop: '0.5rem' }}>
                        <List dense>
                            {variationOpen && variationList}
                            {templateOpen && templateList}
                        </List>

                        {variationOpen
                            ? // Add new variation button
                              !designCodeProject && (
                                  <Box
                                      component="div"
                                      sx={style.variationButtons}
                                  >
                                      <Button
                                          sx={style.listButton}
                                          color="secondary"
                                          variant="outlined"
                                          onClick={onDuplicateVariation}
                                          startIcon={<AddIcon />}
                                          disabled={isSaving}
                                      >
                                          {t(`views.configurator.newVariation`)}
                                      </Button>
                                  </Box>
                              )
                            : // Add new template button
                              !designCodeProject && (
                                  <Box
                                      component="div"
                                      sx={style.variationButtons}
                                  >
                                      <Button
                                          sx={style.listButton}
                                          color="secondary"
                                          variant="outlined"
                                          onClick={onCreateNewTemplate}
                                          startIcon={<AddIcon />}
                                          disabled={isSaving}
                                      >
                                          {t(`views.configurator.newTemplate`)}
                                      </Button>
                                  </Box>
                              )}
                    </Paper>
                ) : undefined}

                {/* Modals */}
                <ItemOptionsMenu
                    open={Boolean(menuAnchorEl)}
                    onClose={onItemsMenuClose}
                    anchorEl={menuAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    // variations
                    variation={menuVariation}
                    variationOpen={variationOpen}
                    onDeleteVariation={props.onDeleteVariation}
                    onOpenRenameVariationDialog={
                        props.onOpenRenameVariationDialog
                    }
                    // templates
                    template={menuTemplate}
                    onDeleteTemplate={props.onDeleteTemplate}
                    // onOpenRenameTemplateDialog={
                    //     props.onOpenRenameTemplateDialog
                    // }
                />
            </Stack>
        </>
    );
};

export default SpatialSwitcher;

const style: any = {
    parent: {
        display: 'inline',
        position: 'absolute',
        right: '1rem',
    },
    paper: {
        padding: '0.5rem',
    },
    tabButton: {},
    variationButtons: {
        display: 'flex',
        gap: '0.5rem',
        flexFlow: 'column nowrap',
    },
    listButton: {
        width: '100%',
    },
};

import React from 'react';
import { Stack, IconButton, Typography } from '@mui/material';
import OpenWithOutlined from '@mui/icons-material/OpenWithOutlined';
import AspectRatio from '@mui/icons-material/AspectRatio';
import Crop from '@mui/icons-material/Crop';
import History from '@mui/icons-material/History';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import ToolButton from './ToolButton';
import { ToolType } from './types';
import { colors } from '../../theming/colors';
import {
    ArrowBackIosNew,
    ArrowForwardIos,
    FlipToBack,
    FlipToFront,
    InfoOutlined,
    Rotate90DegreesCw,
} from '@mui/icons-material';

interface ToolButtonsProps {
    selectedTool: ToolType;
    locked: boolean;
    currentImageIndex: number;
    totalImages: number;
    onToolClick: (tool: ToolType) => void;
}

const ToolButtons: React.FC<ToolButtonsProps> = ({
    selectedTool,
    locked,
    currentImageIndex,
    totalImages,
    onToolClick,
}) => {
    return (
        <Stack direction="row" spacing={0}>
            <ToolButton
                selectedTool={selectedTool}
                toolType="translate"
                locked={locked}
                handleClick={() => onToolClick('translate')}
                IconComponent={<OpenWithOutlined />}
            />
            <ToolButton
                selectedTool={selectedTool}
                toolType="rotate"
                locked={locked}
                handleClick={() => onToolClick('rotate')}
                IconComponent={<Rotate90DegreesCw />}
            />
            <ToolButton
                selectedTool={selectedTool}
                toolType="moveDown"
                locked={locked}
                handleClick={() => onToolClick('moveDown')}
                IconComponent={<FlipToBack />}
            />
            <ToolButton
                selectedTool={selectedTool}
                toolType="moveUp"
                locked={locked}
                handleClick={() => onToolClick('moveUp')}
                IconComponent={<FlipToFront />}
            />
            <ToolButton
                selectedTool={selectedTool}
                toolType="scale"
                locked={locked}
                handleClick={() => onToolClick('scale')}
                IconComponent={<AspectRatio />}
            />

            <ToolButton
                selectedTool={selectedTool}
                toolType="crop"
                locked={locked}
                handleClick={() => onToolClick('crop')}
                IconComponent={<Crop />}
            />

            {/* reset button removed */}

            <IconButton
                onClick={() => onToolClick('lock')}
                sx={style(locked).iconButton}
            >
                {locked ? <Lock /> : <LockOpen />}
            </IconButton>
            <IconButton
                disabled={locked}
                onClick={() => onToolClick('delete')}
                sx={style(locked).iconButton}
            >
                <DeleteOutline />
            </IconButton>
            <ToolButton
                selectedTool={selectedTool}
                toolType="info"
                locked={locked}
                handleClick={() => onToolClick('info')}
                IconComponent={<InfoOutlined />}
            />
            <IconButton
                disabled={locked || totalImages <= 1}
                onClick={() => onToolClick('previous')}
                sx={style(locked).iconButton}
            >
                <ArrowBackIosNew />
            </IconButton>
            <Typography alignSelf={'center'}>
                {currentImageIndex + '/' + totalImages}
            </Typography>
            <IconButton
                disabled={locked || totalImages <= 1}
                onClick={() => onToolClick('next')}
                sx={style(locked).iconButton}
            >
                <ArrowForwardIos />
            </IconButton>
        </Stack>
    );
};

export default ToolButtons;

const style = (locked: boolean): { iconButton: React.CSSProperties } => {
    const val: { iconButton: any } = {
        iconButton: {
            color: locked ? colors.orange : colors.black,
            backgroundColor: locked ? colors.lGrey : 'white',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: colors.borderGrey,
            },
        },
    };
    return val;
};

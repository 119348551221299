import React, { useState } from 'react';

// ui
import ProjectListOptionsMenu from 'components/specific/projectList/ProjectListOptionsMenu';
import ModifyProjectDialog from 'components/specific/projectList/ModifyProjectDialog';
import DeleteProjectDialog from 'components/specific/projectList/DeleteProjectDialog';
import ArchiveProjectDialog from 'components/specific/projectList/ArchiveProjectDialog';
import VersionDialog from 'components/specific/projectList/VersionDialog';
import NewProjectDialog from 'components/specific/projectList/NewProjectDialog';

interface OfferMenuProps {
    onCloseOfferMenu: () => void;
    menuAnchorElProjectMenu: HTMLElement | null;
    activeProjectId: string;
    showArchived?: boolean;
    showTimeCritical?: boolean;
}

export default function OfferMenu(props: OfferMenuProps) {
    // local state

    const [isModifyProjectDialogOpen, setIsModifyProjectDialogOpen] =
        useState(false);
    const [isDeleteProjectDialogOpen, setIsDeleteProjectDialogOpen] =
        useState(false);
    const [isArchiveProjectDialogOpen, setIsArchiveProjectDialogOpen] =
        useState(false);
    const [isVersionDialogOpen, setIsVersionDialogOpen] = useState(false);
    const [isDuplicateProjectDialogOpen, setIsDuplicateProjectDialogOpen] =
        useState(false);

    // features

    return (
        <>
            <ProjectListOptionsMenu
                activeProjectId={props.activeProjectId}
                className="threePoints"
                open={Boolean(props.menuAnchorElProjectMenu)}
                onClose={props.onCloseOfferMenu}
                anchorEl={props.menuAnchorElProjectMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                setIsModifyProjectDialogOpen={() =>
                    setIsModifyProjectDialogOpen(true)
                }
                setIsDeleteProjectDialogOpen={() =>
                    setIsDeleteProjectDialogOpen(true)
                }
                setIsArchiveProjectDialogOpen={() =>
                    setIsArchiveProjectDialogOpen(true)
                }
                setIsVersionDialogOpen={() => setIsVersionDialogOpen(true)}
                setIsDuplicateProjectDialogOpen={() =>
                    setIsDuplicateProjectDialogOpen(true)
                }
            />

            {/* Modals for ProjectListOptionsMenu */}
            <ModifyProjectDialog
                projectId={props.activeProjectId}
                isModifyProjectDialogOpen={isModifyProjectDialogOpen}
                handleModifyProjectDialogClose={() =>
                    setIsModifyProjectDialogOpen(false)
                }
            />
            <DeleteProjectDialog
                projectId={props.activeProjectId}
                isDeleteProjectDialogOpen={isDeleteProjectDialogOpen}
                onCloseDeleteProjectDialog={() =>
                    setIsDeleteProjectDialogOpen(false)
                }
            />
            <ArchiveProjectDialog
                projectId={props.activeProjectId}
                isArchiveProjectDialogOpen={isArchiveProjectDialogOpen}
                onCloseArchiveProjectDialog={() =>
                    setIsArchiveProjectDialogOpen(false)
                }
            />
            <VersionDialog
                projectId={props.activeProjectId}
                isVersionDialogOpen={isVersionDialogOpen}
                onCloseVersionDialog={() => setIsVersionDialogOpen(false)}
                showArchived={props.showArchived || false}
                showTimeCritical={props.showTimeCritical || false}
            />
            {/* Duplicate Project */}
            <NewProjectDialog
                isDuplicate={true}
                open={isDuplicateProjectDialogOpen}
                onClose={() => setIsDuplicateProjectDialogOpen(false)}
                projectId={props.activeProjectId}
            />
        </>
    );
}
